import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Meta, Title} from '@angular/platform-browser';
import {metaData} from '../../../../data/meta-data';

export interface PhoneDialogData {
  title: string;
}

@Component({
  selector: 'app-phone-dialog',
  templateUrl: './phone-dialog.component.html',
  styleUrls: ['./phone-dialog.component.scss']
})
export class PhoneDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PhoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PhoneDialogData,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle(metaData.dialog.bedingungen.title);
    this.meta.updateTag({name: 'description', content: metaData.dialog.phone.description});
    this.meta.updateTag({name: 'keywords', content: metaData.dialog.phone.keywords});
  }

  ngOnInit() {
  }

  closeClick(): void {
    this.dialogRef.close();
  }
}
