<img *ngIf="isHandset" class="big-picture-image"
     (click)="swiping ? swiping = false : toggleBigPictureDisplay()"
     (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')"
     [lazyLoad]="imageUrlPrefix + laden?.images[currentIndex]" [alt]="imageAltText"/>
<button class="close-button" mat-icon-button (click)="closeClick()">
  <mat-icon>close</mat-icon>
</button>
<button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="openHelpOverlay()">
    <mat-icon>help</mat-icon>
    <span>Bedienungshilfe</span>
  </button>
</mat-menu>
<div *ngIf="!laden?.images" class="spinner" fxLayout="column" fxLayoutAlign="center center">
  <mat-spinner color="accent"></mat-spinner>
</div>
<h1 mat-dialog-title [ngClass]="{'hide': bigPictureDisplay}">{{laden?.name}}</h1>
<span *ngIf="isHandset" fxFlex="1 1 auto"></span>
<div *ngIf="!isHandset" mat-dialog-content ngClass.xs="xs">
  <a mat-button color="accent" class="navigate back-button" [routerLink]="getPreviousImageUrl()"
     (click)="previousImage()" fxFlex="40px" fxLayoutAlign="flex-start center"
     [ngStyle]="{'pointer-events': previousEnabled ? 'auto' : 'none'}" [disabled]="!navigationEnabled || !previousEnabled">
    <mat-icon>arrow_back_ios</mat-icon>
  </a>
  <div class="content">
    <div class="image-container" (swipeleft)="nextImage()" (swiperight)="previousImage()">
      <div *ngIf="laden?.images?.length > 0" class="blur-image"
           [@fadeInOut]="backgroundAnimationStateName"
           [ngStyle]="{'background-image': 'url(\'' + imageUrlPrefix + laden.images[currentIndex] + '\')'}">
      </div>
      <img *ngIf="laden?.images?.length > 0" class="image" [@fadeInOut]="imageAnimationStateName"
           [lazyLoad]="imageUrlPrefix + laden.images[currentIndex]" (load)="imageFadeIn()"
           [alt]="imageAltText"/>
    </div>
  </div>
  <a mat-button color="accent" class="navigate forward-button" [routerLink]="getNextImageUrl()"
     (click)="nextImage()" fxFlex="40px" fxLayoutAlign="flex-start center"
     [ngStyle]="{'pointer-events': nextEnabled ? 'auto' : 'none'}" [disabled]="!navigationEnabled || !nextEnabled">
    <mat-icon>arrow_forward_ios</mat-icon>
  </a>
</div>
<div mat-dialog-actions ngClass.xs="xs">
  <div class="detail-text" [ngClass]="{'hide': bigPictureDisplay}"
       [fxLayout]="checklistenButtonReverseOrder ? 'column-reverse' : 'column'"
       fxFlex fxLayoutAlign="center center">
    <button *ngIf="!laden?.isLocalObject" mat-flat-button color="accent" class="checkliste-button"
            (click)="gotoCheckliste()">
      <span>Denk Dran! Deine Checkliste!</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <button *ngIf="laden?.isLocalObject" mat-flat-button color="accent" class="checkliste-button"
            [routerLink]="['/dialog/kontakt']">
      <span>Kontakt</span>
    </button>
    <div fxLayout="column" fxLayoutAlign="center center">
      <span class="detail">{{laden?.text1 ?? laden?.imageTexts[currentIndex]}}</span>
      <span class="detail">{{laden?.text2}}</span>
    </div>
  </div>
  <span *ngIf="laden" class="image-count">
      {{currentIndex + 1}} / {{laden?.images?.length}}
    </span>
</div>
