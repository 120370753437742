import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {LadenDialogComponent} from '../ui/dialog/laden-dialog/laden-dialog.component';
import {CookieStorageService} from '../../services/cookie-storage/cookie-storage.service';
import {EventEmitterService} from '../../services/event-emitter/event-emitter.service';

@Component({
  selector: 'app-page-branche',
  template: '',
})
export class PageBrancheComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private cookieStorage: CookieStorageService,
    private eventemitter: EventEmitterService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (this.cookieStorage.hasAgreed) {
        let dialogRef;
        const branchenId = parseInt(params.branchenId, 10);
        if (branchenId) {
          dialogRef = this.dialog.open(LadenDialogComponent, {
            data: {
              laden: {branchenId}
            },
            autoFocus: false
          });
          dialogRef.afterClosed().subscribe(result => {
            this.router.navigate(['/'], {});
          });
        }
      } else {
        this.eventemitter.emitCookiePopup();
      }
    });
  }

}
