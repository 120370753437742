<button class="close-button" mat-icon-button (click)="closeClick()">
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>Impressum</h1>
<div mat-dialog-content [ngClass.xs]="{'xs': true}">
  <h3>ABC Complett Ladeneinrichtungs GmbH</h3>
  <p>Perelsplatz 18</p>
  <p class="margin-bottom">12159 Berlin</p>
  <p><a href="mailto:kontakt@abc-complett.de">E-Mail schreiben</a></p>
  <p class="margin-bottom"><a href="tel:+49308599710">+49 (0)30 85 99 71 - 0</a></p>
  <p class="margin-bottom">Geschäftsführer: Sven Bückert</p>
  <p>Amtsgericht Berlin-Charlottenburg</p>
  <p class="margin-bottom">92 HRB 43526 / Ust.-Nr. 152509658</p>
  <h3 class="margin-top">Haftungsausschluss</h3>
  <p class="margin-bottom justify">ABC Complett erklärt ausdrücklich, dass zum Zeitpunkt der Linksetzung die
    entsprechenden verlinkten
    Seiten frei von illegalen Inhalten waren. ABC Complett hat keinerlei Einfluss auf die aktuelle und zukünftige
    Gestaltung und auf die Inhalte der gelinkten/verknüpften Seiten. Deshalb distanzieren wir uns hiermit ausdrücklich
    von allen Inhalten aller gelinkten/verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung
    gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge. Für
    illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder
    Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche
    verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.</p>
  <p class="margin-bottom">Gestaltung der Webseite: Elvir Hasanović (<a href="https://www.elvirus.de" target="_blank">elvirus.de</a>)
  </p>
</div>
