import {Component, OnInit} from '@angular/core';
import {Laden, LadenGallery} from '../../models/Laden';
import {AbcComplettApiService} from '../../services/abc-complett-api/abc-complett-api.service';
import {ImageDataType} from '../../enums/ImageDataType';

@Component({
  selector: 'app-branchen',
  templateUrl: './branchen.component.html',
  styleUrls: ['./branchen.component.scss']
})
export class BranchenComponent implements OnInit {

  generalObjects: Laden[];
  startObjects: LadenGallery[];
  imageDataType: ImageDataType = ImageDataType.STARTOBJECTS_BRANCHE;
  autoScrollGallery = true;

  constructor(
    private apiService: AbcComplettApiService,
  ) {
  }

  ngOnInit() {
    this.apiService.getKompetenzObjects().subscribe(data => {
      this.generalObjects = data;
    });
    this.apiService.getStartObjects().subscribe(data => {
      this.startObjects = data.kompetenzen;
    });
  }
}
