<div *ngIf="displayPopup" class="cookie-fixed-container" [ngClass]="{'banner': cookieDenied}" fxLayout="column"
     fxLayoutAlign="center center">
  <div ngClass.xs="xs" class="cookie-content">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="space-between center"
         fxLayoutAlign.xs="center end">
      <div>
        <h2>Cookie Hinweis</h2>
        <p>Für die vollständige Nutzung unserer Website benötigen wir Deine Zustimmung zur Verwendung von Cookies.</p>
        <p>Bitte informiere Dich mit Hilfe unserer <a [routerLink]="['/dialog/datenschutz']">Datenschutzerklärung</a>
          und
          entscheide selbst.</p>
      </div>
      <div class="button-bar" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end center">
        <button mat-flat-button color="accent" (click)="cancelClicked()">Ablehnen</button>
        <button mat-flat-button (click)="confirmClicked()">Annehmen</button>
      </div>
    </div>
  </div>
</div>
