<button class="close-button" mat-icon-button (click)="closeClick()">
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>Datenschutzerklärung</h1>
<div mat-dialog-content [ngClass.xs]="{'xs': true}" [ngClass.gt-xs]="{'gt-xs': true}">
  <div class="scroll-wrapper">
    <perfect-scrollbar>
      <h3>ABC Complett Ladeneinrichtungs GmbH</h3>
      <p class="justify">Der Betreiber dieser Seiten nimmt den Schutz Ihrer persönlichen Daten sehr ernst. Wir
        behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften
        sowie
        dieser Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten
        erhoben.
        Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und
        zu
        welchem Zweck das geschieht.</p>
      <h3 class="margin-top">1. Verantwortlicher</h3>
      <p class="justify">Verantwortlich für die Datenverarbeitung auf dieser Website ist die im Impressum genannte
        Stelle.</p>
      <h3 class="margin-top">2. Datenerfassung auf unserer Website</h3>
      <p class="margin-bottom"><b>Server-Log-Dateien</b></p>
      <p class="margin-bottom justify">Der Provider der Seiten erhebt und speichert automatisch Informationen in so
        genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
      <ul>
        <li>Browsertyp und Browserversion</li>
        <li>verwendetes Betriebssystem</li>
        <li>Referrer URL</li>
        <li>Hostname des zugreifenden Rechners</li>
        <li>Uhrzeit der Serveranfrage</li>
        <li>IP-Adresse</li>
      </ul>
      <p class="margin-bottom justify">Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen.
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO.</p>
      <p class="margin-bottom"><b>Cookies</b></p>
      <p class="margin-bottom justify">Die Internetseiten verwenden teilweise so genannte Cookies. Cookies sind kleine
        Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Sie dienen dazu, unser Angebot
        nutzerfreundlicher, effektiver und sicherer zu machen. Diese Cookies (sogenannte „permanente Cookies“) bleiben
        auf
        Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies würden es uns ermöglichen, Ihren Browser beim
        nächsten Besuch wiederzuerkennen.</p>
      <p class="margin-bottom justify"><b>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
        informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder
        generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der
        Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</b></p>
      <p class="margin-bottom justify">Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur
        Bereitstellung bestimmter von Ihnen erwünschter Funktionen erforderlich sind, werden auf Grundlage von Art. 6
        Abs.
        1 lit. b bzw. f DSGVO gespeichert. Der Websitebetreiber hat darüber hinaus ein berechtigtes Interesse an der
        Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere
        Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser
        Datenschutzerklärung gesondert behandelt.</p>
      <p class="margin-bottom justify">Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc.
        ("Google"). Google Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden
        und
        die eine Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den Cookie erzeugten Informationen
        über
        Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse) werden an einen Server von Google in den USA
        übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung der Website
        auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere
        mit
        der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese
        Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte
        diese
        Daten im Auftrag von Google verarbeiten.</p>
      <p class="margin-bottom justify">Google wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in
        Verbindung bringen. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie
        erhobenen
        Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>
      <p class="margin-bottom"><b>Kontaktformular / Checklistenformular</b></p>
      <p class="margin-bottom justify">Wenn Sie uns per Kontaktformular / Checklistenformular Anfragen zukommen lassen,
        werden Ihre sachlichen Angaben inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
        Anfrage
        und für den Fall von Anschlussfragen bei uns gespeichert. Die von Ihnen im Kontaktformular / Checklistenformular
        eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern oder der Zweck für die
        Datenspeicherung
        entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen –
        insbesondere
        Aufbewahrungsfristen – bleiben unberührt.</p>
      <p class="margin-bottom justify">Die Verarbeitung der in das Kontaktformular / Checklistenformular eingegebenen
        Daten
        erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern es sich um eine konkrete Anfrage zu einem Auftrag
        oder
        einem Bestellvorgang handelt, sowie auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO bei allen weiteren, allgemeinen
        Anfragen.</p>
      <h3 class="margin-top">3. Datenschutzbeauftragter</h3>
      <p class="margin-bottom"><b>Gesetzlich vorgeschriebener Datenschutzbeauftragter</b></p>
      <p class="margin-bottom">Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.</p>
      <p>ABC Complett Ladeneinrichtungs GmbH</p>
      <p>Herr Sven Bückert</p>
      <p>Perelsplatz 18</p>
      <p class="margin-bottom">12159 Berlin</p>
      <p><a href="tel:+49308599710">Telefon: +49 (-30) 859971 – 0</a></p>
      <p>Telefax: +49 (-30) 859971 – 23</p>
      <p class="margin-bottom"><a href="mailto:kontakt@abc-complett.de">E-Mail schreiben</a></p>
      <h3 class="margin-top">4. Datenübermittlung an Dritte</h3>
      <p class="margin-bottom justify">Wir übermitteln Ihre Daten an Dienstleister, die uns beim Betrieb unserer Website
        unterstützen. Sofern dies im Rahmen einer Auftragsverarbeitung nach Art. 28 DSGVO erfolgt, verarbeiten unsere
        Dienstleister Ihre Daten weisungsgebunden und mit entsprechenden vertraglichen Verpflichtungen.</p>
      <p class="margin-bottom"><b>Datenübermittlung in Drittländer</b></p>
      <p class="margin-bottom justify">Teilweise übermitteln wir personenbezogene Daten an ein Drittland außerhalb der
        EU.
        Wir haben dabei jeweils Sorge für ein angemessenes Datenschutzniveau getragen:</p>
      <p class="margin-bottom justify">Im Falle von Google (USA) folgt ein angemessenes Datenschutzniveau aus der
        entsprechenden Teilnahme am Privacy-Shield-Abkommen (Art. 45 Abs. 1 DSGVO).</p>
      <p class="margin-bottom"><b>Google Web Fonts</b></p>
      <p class="margin-bottom justify">Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web
        Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts
        in
        ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>
      <p class="margin-bottom justify">Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern
        von
        Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen
        wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung
        unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p class="margin-bottom justify">Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem
        Computer genutzt.</p>
      <p class="margin-bottom justify">Weitere Informationen zu Google Web Fonts finden Sie unter
        <a href="https://developers.google.com/fonts/faq" target="_blank">Link zur FAQ von Google-Developers</a> und
        in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy/" target="_blank">Link
          zur Datenschutzerklärung von Google</a>.</p>
      <p class="margin-bottom"><b>Google Maps</b></p>
      <p class="margin-bottom justify">Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die
        Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
      <p class="margin-bottom justify">Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu
        speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort
        gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.</p>
      <p class="margin-bottom justify">Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung
        unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
        stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</p>
      <p class="margin-bottom justify">Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von Google: <a href="https://www.google.de/intl/de/policies/privacy/" target="_blank">Link
          zur Datenschutzerklärung von Google</a>.</p>
      <h3 class="margin-top">5. Ihre Rechte als Nutzer</h3>
      <p class="margin-bottom"><b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b></p>
      <p class="margin-bottom justify">Einige Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung
        möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
        Mitteilung
        per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
        unberührt.</p>
      <p class="margin-bottom"><b>Beschwerderecht bei der zuständigen Aufsichtsbehörde</b></p>
      <p class="margin-bottom justify">Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
        Beschwerderecht
        bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
        Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der
        Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: <a
          href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">
          Link zum Verzeichnis der Anschriften beim BfDI</a>.</p>
      <p class="margin-bottom"><b>Recht auf Datenübertragbarkeit</b></p>
      <p class="margin-bottom justify">Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
        Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
        maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
      <p class="margin-bottom"><b>SSL- bzw. TLS-Verschlüsselung</b></p>
      <p class="margin-bottom justify">Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der
        unverschlüsselten Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten
        vor
        dem Zugriff durch Dritte ist nicht möglich.</p>
      <p class="margin-bottom justify">Diese Seite nutzt deshalb aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden,
        eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
        Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
      <p class="margin-bottom justify">Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie
        an
        uns übermitteln, nicht von Dritten mitgelesen werden.</p>
      <p class="margin-bottom"><b>Auskunft, Sperrung, Löschung</b></p>
      <p class="margin-bottom justify">Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht
        auf
        unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
        Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu
        sowie
        zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse an uns wenden.</p>
      <p class="margin-bottom">Ihr Auskunftsverlangen können Sie gegenüber folgender Anschrift geltend machen:</p>
      <p class="margin-bottom"><b>ABC Complett Ladeneinrichtungs GmbH</b></p>
      <p>Perelsplatz 18</p>
      <p class="margin-bottom">12159 Berlin</p>
      <p><a href="tel:+49308599710">Telefon: +49 (-30) 859971 – 0</a></p>
      <p>Telefax: +49 (-30) 859971 – 23</p>
      <p><a href="mailto:kontakt@abc-complett.de">E-Mail schreiben</a></p>
    </perfect-scrollbar>
  </div>
</div>
