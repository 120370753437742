import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieStorageService {

  private readonly COOKIE_AGREED_KEY = 'cookies_accepted';

  private userHasAgreed?: boolean;

  get hasDenied(): boolean {
    if (this.userHasAgreed !== undefined) {
      return !this.userHasAgreed.valueOf();
    } else {
      return false;
    }
  }

  get hasAgreed(): boolean {
    if (this.userHasAgreed) {
      return this.userHasAgreed;
    } else {
      return false;
    }
  }

  set hasAgreed(value: boolean) {
    this.userHasAgreed = value;
    if (value) {
      localStorage.setItem(this.COOKIE_AGREED_KEY, 'true');
    } else {
      localStorage.setItem(this.COOKIE_AGREED_KEY, 'false');
    }
  }

  constructor() {
    const storageItem = localStorage.getItem(this.COOKIE_AGREED_KEY);
    if (storageItem) {
      this.userHasAgreed = storageItem === 'true';
    }
  }
}
