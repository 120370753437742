<button class="close-button" mat-icon-button (click)="closeClick()">
  <mat-icon>close</mat-icon>
</button>
<h1 *ngIf="!landscapeHandset" mat-dialog-title>Kontakt</h1>
<div #dialogContent mat-dialog-content [ngClass.xs]="{'xs': true}">
  <div class="dialog" fxLayout="column" fxLayoutAlign="center center">
    <div *ngIf="succeded" fxLayout="column" fxLayoutAlign="center center">
      <svg id="successAnimation" class="animated" xmlns="http://www.w3.org/2000/svg" width="70" height="70"
           viewBox="0 0 70 70">
        <path id="successAnimationResult" fill="#D8D8D8"
              d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z"/>
        <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" stroke-width="2"
                stroke-linecap="round" fill="transparent"/>
        <polyline id="successAnimationCheck" stroke="#979797" stroke-width="2" points="23 34 34 43 47 27"
                  fill="transparent"/>
      </svg>
      <small>Die Nachricht wurde erfolgreich übermittelt.</small>
    </div>
    <div *ngIf="!succeded" fxLayout="column" fxLayoutGap="10px">
      <small>Sende uns eine <a href="mailto:kontakt@abc-complett.de">E-Mail</a>, ruf uns an unter <a
        href="tel:+49308599710">030 85 99 71 - 0</a>
        <div>oder nutze unser Kontaktformular:</div>
      </small>
      <form id="contactForm" name="contactForm" (ngSubmit)="sendForm()" #contactForm="ngForm">
        <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column">
          <div fxLayout="column">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Firma</mat-label>
              <input (focus)="inputFocus($event)" matInput type="text" name="firma" [(ngModel)]="contact.firma"
                     tabindex="1"/>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Adresse</mat-label>
              <input (focus)="inputFocus($event)" matInput type="text" name="adresse" [(ngModel)]="contact.adresse"
                     tabindex="2"/>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>PLZ Stadt</mat-label>
              <input (focus)="inputFocus($event)" matInput type="text" name="stadt" [(ngModel)]="contact.stadt"
                     tabindex="3"/>
            </mat-form-field>
          </div>
          <div fxLayout="column">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Name</mat-label>
              <input (focus)="inputFocus($event)" matInput type="text" name="name" [(ngModel)]="contact.name" required
                     tabindex="4"/>
              <mat-error>Bitte Namen eintragen</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>E-Mail Adresse</mat-label>
              <input (focus)="inputFocus($event)" matInput #email="ngModel" type="text" name="email"
                     [(ngModel)]="contact.email" required email tabindex="5"/>
              <mat-error *ngIf="email.invalid && email.errors.required">Bitte E-Mail eintragen</mat-error>
              <mat-error *ngIf="email.invalid && email.errors.email">Gültige E-Mail eintragen</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Telefonnummer</mat-label>
              <input (focus)="inputFocus($event)" matInput type="text" pattern="[0-9 \+\(\)-]+" name="telefon"
                     [(ngModel)]="contact.telefon" required tabindex="6"/>
              <mat-error>Bitte Nummer eintragen</mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field class="full-width" appearance="outline" color="accent">
          <mat-label>Anliegen</mat-label>
          <textarea (focus)="inputFocus($event)" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="4" cdkAutosizeMaxRows="6" name="bemerkungen" [(ngModel)]="contact.bemerkungen"
                    tabindex="7"></textarea>
        </mat-form-field>
        <div fxLayout="column" fxLayoutAlign="center start"
             [ngStyle]="{'padding-bottom': landscapeHandset ? dialogContent.clientHeight - 28 + 'px' : '0px'}">
          <small>Terminvereinbarung gewünscht per</small>
          <mat-checkbox name="check_email" [(ngModel)]="contact.kontaktPerMail" tabindex="8">E-Mail</mat-checkbox>
          <mat-checkbox name="check_telefon" [(ngModel)]="contact.kontaktPerTelefon" tabindex="9">Telefon</mat-checkbox>
        </div>
      </form>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div fxLayout="column">
    <mat-error *ngIf="error">{{error}}</mat-error>
    <div fxLayout="row">
      <button mat-flat-button color="accent" *ngIf="!succeded" type="submit" form="contactForm">Senden</button>
    </div>
  </div>
</div>
