import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Laden} from '../../../../models/Laden';
import {EventEmitterService} from '../../../../services/event-emitter/event-emitter.service';
import {AbcComplettApiService} from '../../../../services/abc-complett-api/abc-complett-api.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {Observable, Subscription} from 'rxjs';
import {ImageDataType} from '../../../../enums/ImageDataType';
import {Meta, Title} from '@angular/platform-browser';
import {metaData} from '../../../../data/meta-data';
import {HilfeDialogComponent} from '../hilfe-dialog/hilfe-dialog.component';
import {Location} from '@angular/common';

export interface LadenDialogData {
  laden: Laden;
  localUrls?: boolean;
  localUrlsName?: string;
  selectedLocalIndex?: number;
  type?: ImageDataType;
  currentIndex?: number;
}

const animationDefaultDuration = 150;

@Component({
  selector: 'app-laden-dialog',
  templateUrl: './laden-dialog.component.html',
  styleUrls: ['./laden-dialog.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('hide', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('show => hide', animate(animationDefaultDuration + 'ms ease-out')),
      transition('hide => show', animate(animationDefaultDuration + 'ms ease-in'))
    ]),
  ]
})
export class LadenDialogComponent implements OnInit, OnDestroy {
  private showBackgroundImage = true;
  private showImage = false;
  private isLandscape = false;
  private handsetChange: Observable<BreakpointState>;
  private tabletChange: Observable<BreakpointState>;
  private webChange: Observable<BreakpointState>;
  private landscapeChange: Observable<BreakpointState>;
  private portraitChange: Observable<BreakpointState>;
  private handsetChangeSubscripion: Subscription;
  private tabletChangeSubscripion: Subscription;
  private webChangeSubscripion: Subscription;
  private landscapeChangeSubscripion: Subscription;
  private portraitChangeSubscripion: Subscription;
  private helpDialogOpenedOnPinch = false;
  private swipeCoord?: [number, number];
  private swipeTime?: number;
  private previousImageUrl?: string = null;
  private nextImageUrl?: string = null;

  laden: Laden;
  currentIndex = 0;
  navigationEnabled = true;
  bigPictureDisplay = false;
  swiping = false;
  isHandset = false;
  checklistenButtonReverseOrder = false;
  previousEnabled = true;
  nextEnabled = true;

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowRight') {
      this.nextImage();
    } else if (event.key === 'ArrowLeft') {
      this.previousImage();
    }
  }

  get imageAltText(): string {
    if (this.laden) {
      return [this.laden.name, this.laden.text1, this.laden.text2].filter(Boolean).join(' - ');
    }
    return '';
  }

  get imageUrlPrefix(): string {
    if (this.data.localUrls) {
      return '/assets/images/news/' + this.data.localUrlsName + '/';
    }
    return 'https://images.abc-complett.de';
  }

  get backgroundAnimationStateName() {
    return this.showBackgroundImage ? 'show' : 'hide';
  }

  get imageAnimationStateName() {
    return this.showImage ? 'show' : 'hide';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LadenDialogData,
    private apiService: AbcComplettApiService,
    private eventEmitterService: EventEmitterService,
    public dialogRef: MatDialogRef<LadenDialogComponent>,
    private breakpointObserver: BreakpointObserver,
    private title: Title,
    private meta: Meta,
    public dialog: MatDialog,
  ) {
    this.handsetChange = this.breakpointObserver.observe([
      Breakpoints.Handset
    ]);
    this.tabletChange = this.breakpointObserver.observe([
      Breakpoints.Tablet
    ]);
    this.webChange = this.breakpointObserver.observe([
      Breakpoints.Web
    ]);
    this.landscapeChange = this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]);
    this.portraitChange = this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]);
    if (this.data.localUrls && this.data.selectedLocalIndex) {
      this.currentIndex = this.data.selectedLocalIndex;
    } else if (this.data.currentIndex) {
      this.currentIndex = this.data.currentIndex;
    }
  }

  ngOnInit() {
    this.portraitChangeSubscripion = this.portraitChange.subscribe(result => {
      if (result.matches) {
        this.isLandscape = false;
        this.bigPictureDisplay = false;
      }
    });
    this.landscapeChangeSubscripion = this.landscapeChange.subscribe(result => {
      if (result.matches) {
        this.isLandscape = true;
      }
    });
    this.handsetChangeSubscripion = this.handsetChange.subscribe(result => {
      if (result.matches) {
        this.isHandset = true;
        if (!document.body.classList.contains('big-picture')) {
          document.body.classList.add('big-picture');
        }
      }
    });
    this.tabletChangeSubscripion = this.tabletChange.subscribe(result => {
      if (result.matches) {
        this.isHandset = false;
        if (document.body.classList.contains('big-picture')) {
          document.body.classList.remove('big-picture');
        }
      }
    });
    this.webChangeSubscripion = this.webChange.subscribe(result => {
      if (result.matches) {
        this.isHandset = false;
        if (document.body.classList.contains('big-picture')) {
          document.body.classList.remove('big-picture');
        }
      }
    });
    if (this.data.laden.id) {
      this.apiService.getObjectPicturesByObjectId(this.data.laden.id).subscribe(data => {
        this.laden = data;
        this.setPreviousImageUrl();
        this.setNextImageUrl();
        this.title.setTitle(metaData.dialog.laden.title.replace('{0}', this.laden.name).replace('{0}', this.laden.branche));
        this.meta.updateTag({name: 'description', content: metaData.dialog.laden.description.replace('{0}', this.laden.name)});
        this.updateKeywords();
      });
    } else if (this.data.laden.branchenId) {
      this.data.type = ImageDataType.DEFAULT_BRANCHE;
      this.apiService.getObjectPicturesByBranchenId(this.data.laden.branchenId).subscribe(data => {
        this.laden = data;
        this.setPreviousImageUrl();
        this.setNextImageUrl();
        this.title.setTitle(metaData.dialog.laden.title.replace('{0}', this.laden.name).replace('{0}', this.laden.branche));
        this.meta.updateTag({name: 'description', content: metaData.dialog.laden.description.replace('{0}', this.laden.name)});
        this.updateKeywords();
      });
    } else {
      this.laden = this.data.laden;
      this.setPreviousImageUrl();
      this.setNextImageUrl();
      this.title.setTitle(metaData.dialog.laden.titleShort.replace('{0}', this.laden.name));
      this.meta.updateTag({name: 'description', content: metaData.dialog.laden.description.replace('{0}', this.laden.name)});
      this.updateKeywords();
    }
    this.eventEmitterService.emitDisableScroll(true);
  }

  ngOnDestroy() {
    this.title.setTitle(metaData.default.title);
    this.meta.updateTag({name: 'description', content: metaData.default.description});
    this.meta.updateTag({name: 'keywords', content: metaData.default.keywords});
    if (document.body.classList.contains('big-picture')) {
      document.body.classList.remove('big-picture');
    }
    this.portraitChangeSubscripion.unsubscribe();
    this.landscapeChangeSubscripion.unsubscribe();
    this.handsetChangeSubscripion.unsubscribe();
    this.tabletChangeSubscripion.unsubscribe();
    this.webChangeSubscripion.unsubscribe();
    this.eventEmitterService.emitDisableScroll(false);
  }

  private updateKeywords() {
    this.apiService.getSearchKeywordsByBranchenId(this.laden.branchenId).subscribe(data => {
      let keywords = '';
      for (const keyword of data) {
        keywords += keyword.begriff + ', ';
      }
      keywords = keywords.slice(0, keywords.length - 2);
      this.meta.updateTag({name: 'keywords', content: metaData.dialog.laden.keywords.replace('{0}', keywords)});
    });
  }

  backgroundImageFadeOut() {
    this.showBackgroundImage = false;
  }

  backgroundImageFadeIn() {
    this.showBackgroundImage = true;
  }

  imageFadeOut() {
    this.showImage = false;
  }

  imageFadeIn() {
    this.showImage = true;
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  gotoCheckliste() {
    this.dialogRef.close();
    const sectionId = 'denkdran';
    const scroller = document.getElementById('app-root');
    const element = document.getElementById(sectionId);
    const fixHeader = document.getElementById('fix-header');
    const secondHeader = document.getElementById('second-header');
    const headerHeight = (fixHeader ? fixHeader.clientHeight : 0) + (secondHeader ? secondHeader.clientHeight : 0);
    const elementRect = element.offsetTop;
    const elementPosition = elementRect - headerHeight;

    setTimeout(() => {
      scroller.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    }, 500);
    if (this.laden && this.laden.branchenId) {
      this.eventEmitterService.emitChecklistenBranchenSelectionSet(this.laden.branchenId);
    } else {
      this.eventEmitterService.emitChecklistenBranchenSelectionSet(undefined);
    }
  }

  getPreviousImageUrl(): string {
    if (this.previousImageUrl) {
      return this.previousImageUrl;
    }

    return '';
  }

  getNextImageUrl(): string {
    if (this.nextImageUrl) {
      return this.nextImageUrl;
    }

    return '';
  }

  private setPreviousImageUrl() {
    if (this.currentIndex > 0) {
      this.previousEnabled = true;
      const name = this.laden.name.replace(new RegExp(' ', 'g'), '-');
      if (this.data.localUrls) {
        this.previousImageUrl = '/shop/' + this.laden.id + '/' + this.data.selectedLocalIndex + '/' + this.data.localUrlsName + '/' + this.laden.branche + '/' + this.currentIndex;
      } else {
        this.previousImageUrl = '/shop/' + this.laden.id + '/' + this.data.type + '/' + name + '/' + this.laden.branche + '/' + this.currentIndex;
      }
    } else {
      this.previousEnabled = true;
      if (this.data.localUrls) {
        this.previousImageUrl = '/shop/' + this.laden.id + '/' + this.data.selectedLocalIndex + '/' + this.data.localUrlsName + '/' + this.laden.branche + '/' + this.laden.images.length;
      } else {
        this.apiService.getPreviousObject(this.laden, this.data.type).subscribe(data => {
          const name = data.name.replace(new RegExp(' ', 'g'), '-');
          this.previousImageUrl = '/shop/' + data.id + '/' + this.data.type + '/' + name + '/' + data.branche + '/' + data.images.length;
        }, error => {
          this.previousEnabled = false;
        });
      }
    }
  }

  private setNextImageUrl() {
    if (this.currentIndex < this.laden.images.length - 1) {
      this.nextEnabled = true;
      const name = this.laden.name.replace(new RegExp(' ', 'g'), '-');
      if (this.data.localUrls) {
        this.nextImageUrl = '/shop/' + this.laden.id + '/' + this.data.selectedLocalIndex + '/' + this.data.localUrlsName + '/' + this.laden.branche + '/' + (this.currentIndex + 2);
      } else {
        this.nextImageUrl = '/shop/' + this.laden.id + '/' + this.data.type + '/' + name + '/' + this.laden.branche + '/' + (this.currentIndex + 2);
      }
    } else {
      this.nextEnabled = true;
      if (this.data.localUrls) {
        this.nextImageUrl = '/shop/' + this.laden.id + '/' + this.data.selectedLocalIndex + '/' + this.data.localUrlsName + '/' + this.laden.branche + '/1';
      } else {
        this.apiService.getNextObject(this.laden, this.data.type).subscribe(data => {
          const name = data.name.replace(new RegExp(' ', 'g'), '-');
          this.nextImageUrl = '/shop/' + data.id + '/' + this.data.type + '/' + name + '/' + data.branche + '/1';
        }, error => {
          this.nextEnabled = false;
        });
      }
    }
  }

  nextImage() {
    this.nextImageUrl = null;
    this.navigationEnabled = false;
    this.backgroundImageFadeOut();
    this.imageFadeOut();
    setTimeout(() => {
      if (this.currentIndex < this.laden.images.length - 1) {
        this.navigationEnabled = true;
        this.currentIndex = this.currentIndex + 1;
        this.setPreviousImageUrl();
        this.setNextImageUrl();
        this.backgroundImageFadeIn();
      } else {
        if (this.data.localUrls) {
          this.currentIndex = 0;
          this.setPreviousImageUrl();
          this.setNextImageUrl();
          this.backgroundImageFadeIn();
          this.navigationEnabled = true;
        } else {
          this.apiService.getNextObject(this.laden, this.data.type).subscribe(data => {
            this.laden = data;
            this.currentIndex = 0;
            this.setPreviousImageUrl();
            this.setNextImageUrl();
            this.backgroundImageFadeIn();
            this.navigationEnabled = true;
            this.highlightChecklistenButton();
            this.title.setTitle(metaData.dialog.laden.titleShort.replace('{0}', this.laden.name));
            this.meta.updateTag({name: 'description', content: metaData.dialog.laden.description.replace('{0}', this.laden.name)});
          });
          this.laden = undefined;
        }
      }
    }, animationDefaultDuration);
  }

  previousImage() {
    this.navigationEnabled = false;
    this.backgroundImageFadeOut();
    this.imageFadeOut();
    setTimeout(() => {
      if (this.currentIndex > 0) {
        this.navigationEnabled = true;
        this.currentIndex = this.currentIndex - 1;
        this.setPreviousImageUrl();
        this.setNextImageUrl();
        this.backgroundImageFadeIn();
      } else {
        if (this.data.localUrls) {
          this.currentIndex = this.laden.images.length - 1;
          this.setPreviousImageUrl();
          this.setNextImageUrl();
          this.backgroundImageFadeIn();
          this.navigationEnabled = true;
        } else {
          this.apiService.getPreviousObject(this.laden, this.data.type).subscribe(data => {
            this.laden = data;
            this.currentIndex = this.laden.images.length - 1;
            this.setPreviousImageUrl();
            this.setNextImageUrl();
            this.backgroundImageFadeIn();
            this.navigationEnabled = true;
            this.highlightChecklistenButton();
            this.title.setTitle(metaData.dialog.laden.titleShort.replace('{0}', this.laden.name));
            this.meta.updateTag({name: 'description', content: metaData.dialog.laden.description.replace('{0}', this.laden.name)});
          });
          this.laden = undefined;
        }
      }
    }, animationDefaultDuration);
  }

  private highlightChecklistenButton() {
    this.checklistenButtonReverseOrder = !this.checklistenButtonReverseOrder;
  }

  toggleBigPictureDisplay() {
    if (!this.swiping && this.isLandscape) {
      this.bigPictureDisplay = !this.bigPictureDisplay;
    }
  }

  openHelpOverlay(event?) {
    if (event && event.type === 'pinch') {
      if (!this.helpDialogOpenedOnPinch) {
        this.helpDialogOpenedOnPinch = true;
        const dialogRef = this.dialog.open(HilfeDialogComponent, {
          autoFocus: false
        });
        dialogRef.afterClosed().subscribe(result => {
          this.helpDialogOpenedOnPinch = false;
        });
      }
    } else {
      this.dialog.open(HilfeDialogComponent, {
        autoFocus: false
      });
    }
  }

  swipe(event: TouchEvent, when: string): void {
    const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if (duration < 1000
        && Math.abs(direction[0]) > 30 // Long enough
        && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (swipe === 'next') {
          this.nextImage();
        } else {
          this.previousImage();
        }
      } else if (duration < 1000
        && Math.abs(direction[1]) > 30
        && Math.abs(direction[1]) > Math.abs(direction[0] * 3)) {
        this.openHelpOverlay();
      }
    }
  }
}
