<app-header></app-header>
<app-branchen></app-branchen>
<div id="news" class="pre-section" snInViewport (inViewportChange)="onInViewportChange($event, 'news')"
     [inViewportOptions]="{
      rootMargin: '300px 0px 0px 0px'
    }">
  <div *ngIf="!isHandset || newsLoaded">
    <ng-container *ngComponentOutlet="newsComponent"></ng-container>
  </div>
</div>
<div class="pre-section blue search" snInViewport (inViewportChange)="onInViewportChange($event, 'search')"
     [inViewportOptions]="{
      rootMargin: '300px 0px 0px 0px'
    }">
  <div *ngIf="!isHandset || searchLoaded">
    <ng-container *ngComponentOutlet="searchComponent"></ng-container>
  </div>
</div>
<div id="referenzen" class="pre-section" snInViewport (inViewportChange)="onInViewportChange($event, 'referenzen')"
     [inViewportOptions]="{
      rootMargin: '300px 0px 0px 0px'
    }">
  <div *ngIf="!isHandset || referenzenLoaded">
    <ng-container *ngComponentOutlet="referenzenComponent"></ng-container>
  </div>
</div>
<div id="gemeinsam" class="pre-section dark" snInViewport (inViewportChange)="onInViewportChange($event, 'warumwir')"
     [inViewportOptions]="{
      rootMargin: '300px 0px 0px 0px'
    }">
  <div *ngIf="!isHandset || warumwirLoaded">
    <ng-container *ngComponentOutlet="warumwirComponent"></ng-container>
  </div>
</div>
<div id="denkdran" class="pre-section" snInViewport (inViewportChange)="onInViewportChange($event, 'checkliste')"
     [inViewportOptions]="{
      rootMargin: '300px 0px 0px 0px'
    }">
  <div *ngIf="!isHandset || checklisteLoaded">
    <ng-container *ngComponentOutlet="checklisteComponent"></ng-container>
  </div>
</div>
<div class="pre-section dark footer" snInViewport (inViewportChange)="onInViewportChange($event, 'footer')"
     [inViewportOptions]="{
      rootMargin: '300px 0px 0px 0px'
    }">
  <div *ngIf="!isHandset || footerLoaded">
    <ng-container *ngComponentOutlet="footerComponent"></ng-container>
  </div>
</div>
<router-outlet></router-outlet>
