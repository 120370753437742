<section id="kompetenzen" ngClass.lt-sm="small">
  <div class="title-container" fxLayout="row">
    <h2 fxFlex="1 1 auto" class="left-title">Kompetenzen!</h2>
    <h2 fxHide.lt-md class="center-title">Mehr als Branchen</h2>
    <h2 fxHide.lt-md fxFlex="1 1 auto" class="center-clone">Kompetenzen!</h2>
  </div>
  <app-gallery-scroller class="gallery first" ngClass.lt-md="lt-md" [ladenObjects]="generalObjects"
                        [imageDataType]="imageDataType" [autoScrolling]="autoScrollGallery"
                        (mousedown)="autoScrollGallery = false" (pointerdown)="autoScrollGallery = false"
                        [showIndividualLabels]="true"></app-gallery-scroller>
  <ng-container *ngFor="let gallery of startObjects">
    <h3 ngClass.lt-md="lt-md">{{gallery.kompetenz}}</h3>
    <app-gallery-scroller class="gallery" ngClass.lt-md="lt-md" [mediumImages]="true"
                          [ladenObjects]="gallery.ladenObjects" (mousedown)="autoScrollGallery = false"
                          (pointerdown)="autoScrollGallery = false"
                          [imageDataType]="imageDataType"></app-gallery-scroller>
  </ng-container>
</section>
