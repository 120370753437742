import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    @Inject(DOCUMENT) private document,
  ) {
  }

  createLinkForCanonicalURL(url: string) {
    const canonicalUrl = 'https://www.abc-complett.de' + url;
    const link = this.document.querySelector('link[rel="canonical"]');
    if (link) {
      this.document.head.removeChild(link);
    }
    const canonicalLink: HTMLLinkElement = this.document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', canonicalUrl);
    this.document.head.appendChild(canonicalLink);
  }
}
