import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Meta, Title} from '@angular/platform-browser';
import {metaData} from '../../../../data/meta-data';

@Component({
  selector: 'app-bedingungen-dialog',
  templateUrl: './bedingungen-dialog.component.html',
  styleUrls: ['./bedingungen-dialog.component.scss']
})
export class BedingungenDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BedingungenDialogComponent>,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle(metaData.dialog.bedingungen.title);
    this.meta.updateTag({name: 'description', content: metaData.dialog.bedingungen.description});
    this.meta.updateTag({name: 'keywords', content: metaData.dialog.bedingungen.keywords});
  }

  ngOnInit() {
  }

  closeClick(): void {
    this.dialogRef.close();
  }

}
