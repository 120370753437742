<button class="close-button" mat-icon-button (click)="closeClick()">
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>Verkaufs- und Lieferbedingungen</h1>
<div mat-dialog-content [ngClass.xs]="{'xs': true}" [ngClass.gt-xs]="{'gt-xs': true}">
  <div class="scroll-wrapper">
    <perfect-scrollbar>
      <h3>ABC Complett Ladeneinrichtungs GmbH</h3>
      <p class="margin-bottom"><b>I. Allgemeine Bedingungen:</b></p>
      <p class="margin-bottom justify">Die nachfolgenden Bestimmungen werden vom Käufer als verbindlich für den
        Geschäftsverkehr mit ABC Complett GmbH ( im folgenden ABC Complett genannt ), insbesondere für einen erteilten
        Auftrag anerkannt. Abänderungen und Ergänzungen dieser Bestimmungen müssen in jedem Einzelfall schriftlich
        vereinbart werden. Ein erteilter Auftrag wird für ABC Complett erst durch Ihre schriftliche Auftragsbestätigung
        verbindlich.</p>
      <p class="margin-bottom"><b>II. Umfang der Lieferpflicht:</b></p>
      <ol>
        <li>Die Angebote von ABC Complett werden erst durch schriftliche Bestätigung des Auftrages durch ABC Complett
          verbindlich. Sie gelten in €.
        </li>
        <li>Für den Inhalt des Gesamtvertrages insbesondere für den Umfang und die Ausführung der Lieferung ist alleine
          die
          schriftliche Auftragsbestätigung von ABC Complett maßgebend.
        </li>
        <li>Die dem Käufer von ABC Complett zur Verfügung gestellten Unterlagen, wie z.B. Kostenanschläge, Kataloge,
          Zeichnungen und ähnliches, bleiben Eigentum von ABC-Complett. Sie dürfen Dritten nicht zugänglich gemacht
          werden.
          Sie dürfen auch vom Käufer nicht verwendet werden, wenn der Auftrag nicht zustande kommt oder nachträglich
          aufgehoben wird. In diesem Falle sind sie unaufgefordert und unverzüglich an ABC Complett zurückzugeben.
        </li>
        <li>ABC Complett behält sich vor, vom Inhalt der von ihr angefertigten und vorgelegten Listen und Kataloge, von
          Abbildungen und anderem Prospektmaterial hinsichtlich Form, Farbgebung, Material und Gewicht abzuweichen,
          soweit
          hierdurch der Inhalt des erteilten Auftrages nicht im wesentlichen geändert wird.
        </li>
        <li>Soweit eine Kompensation der Elektroinstallation bei der Einrichtung erforderlich ist, wird dieser nicht
          durch
          ABC Complett, sondern vom Verkäufer auf dessen Verantwortung und dessen Kosten veranlasst.
        </li>
      </ol>
      <p class="margin-bottom"><b>III. Preise:</b></p>
      <ol>
        <li>Die Preise von ABC Complett beinhalten ebenso wie die Montagekosten, Lieferung von Sonderteilen, sowie
          sonstige
          vergütete Leistungen keine Umsatzsteuer / Mehrwertsteuer. Diese wird in ihrer jeweils gültigen Höhe den
          Preisen
          von ABC Complett zugeschlagen.
        </li>
        <li>Vom Käufer verlangte Leistungsänderungen, ferner erbrachte Mehrleistungen, werden zusätzlich berechnet.</li>
        <li>Alle Preise verstehen sich ab Werk. Als Nettowarenwert gilt der reine Warenwert ohne Montagekosten und
          Umsatzsteuer/ Mehrwertsteuer.
        </li>
        <li>Ist ein ca. Preis vereinbart, bildet dieser einen Richtpreis für den von ABC Complett zu errechnenden
          endgültigen
          Kaufpreis mit der Maßgabe, daß eine Über- und Unterschreitung des ca. - Preises um je 15 % als möglich
          vereinbart
          ist. Der endgültige Kaufpreis ergibt sich aus dem in der Auftragsbestätigung genannten Gesamtpreis.
        </li>
      </ol>
      <p class="margin-bottom"><b>IV. Lieferzeit:</b></p>
      <ol>
        <li>Sofern von ABC Complett Lieferzeiten genannt werden, beginnen sie mit dem Datum der Auftragsbestätigung oder
          im
          Falle vereinbarter Anzahlung mit dem Tag des Eingangs der vereinbarten Anzahlung. In den Auftragsbestätigungen
          genannte Lieferdaten stellen den Abgangstag der Ware bei ABC Complett dar.
        </li>
        <li>Die angegebenen Lieferfristen oder Lieferdaten werden von ABC Complett nach Möglichkeit eingehalten. Bei
          Fristüberschreitungen gilt eine Nachfrist von einem Monat als vereinbart.
        </li>
        <li>Hat ABC Complett dem Kunden anstelle eines fest bestimmten Liefertermins zugesagt, die Ware auf Abruf
          lieferbereit zu halten, so ist ABC Complett äußerstenfalls verpflichtet, die bestellte Ware sechs Monate für
          den
          Käufer zur Verfügung zu halten. Die Frist rechnet ab Datum der Auftragsbestätigung. Fehlt nach Ablauf von
          sechs
          Monaten ein Abruf und hat ABC Complett dem Käufer eine Nachfrist zum Abruf von 14 Tagen unter Hinweis auf die
          Rechtsfolgen gesetzt, so ist ABC Complett berechtigt, ohne weitere Fristsetzung Schadenersatz wegen
          Nichterfüllung
          zu verlangen. Als Schaden sind 20% vom Warenwert im Sinne von III 3. + 4. dieser Bedingungen vereinbart.
        </li>
        <li>Ruft der Kunde innerhalb der Sechs-Monatsfrist oder der ihm gesetzten Nachfrist ab, so liefert ABC Complett
          innerhalb eines Zeitraumes von 4 Wochen, gerechnet vom Eingang des Abrufes bei ABC Complett.
        </li>
        <li>ABC Complett ist zu angemessenen Teilleistungen berechtigt . Hierdurch etwa entstehende Mehrkosten gehen
          mangels
          anderweitiger Vereinbarung zu Lasten von ABC Complett.
        </li>
        <li>Höhere Gewalt, Materialmangel, Verkehrsbeschränkungen , nicht rechtzeitige oder nicht einwandfreie
          Selbstbelieferung und sonstige von ABC Complett nicht zu vertretende Hindernisse sowie alle
          Arbeitskampfmaßnahmen
          verlängern die vereinbarte Lieferzeit entsprechend.
        </li>
        <li>Entschädigungsansprüche des Käufers gegen ABC Complett, gleich welcher Art, die ihre Begründung in
          verspäteter
          Lieferung haben, sind ausgeschlossen, sofern die verspätete Lieferung nicht durch Vorsatz oder grobe
          Fahrlässigkeit auf Seiten von ABC Complett oder ihrer Mitarbeiter verursacht worden ist.
        </li>
      </ol>
      <p class="margin-bottom"><b>V. Versand und Gefahrenübergang:</b></p>
      <ol>
        <li>Bei Versand durch die Bahn ist der Transport von der Bahnstation zur Montagestelle Sache des Käufers.</li>
        <li>Die Gefahr des zufälligen Untergangs und der Verschlechterung der Ware geht mit der übergabe an den
          Spediteur
          oder eine sonstige Transportperson, spätestens mit Verlassen des Betriebsgeländes des Herstellers der Ware auf
          den
          Käufer über. Auch der Transport erfolgt auf Gefahr des Käufers. Die Gefahr geht auch auf den Käufer über, wenn
          ABC
          Complett die Montage übernommen hat. Die vorstehende Regelung gilt auch bei frachtfreier Lieferung. Das
          gleiche
          gilt, wenn anstelle des Käufers, Monteure oder andere Personen von ABC Complett oder im Auftrage von ABC
          Complett
          die gelieferte Ware zum Zwecke der Montage in Empfang nehmen.
        </li>
        <li>Bei Anlieferung durch LKW stellt der Käufer auf seine Kosten Personal zur Entladung und Transport der Ware
          zur
          Montagestelle bereit.
        </li>
        <li>Die Lieferung wird von ABC Complett nicht versichert . Der Käufer ist dennoch verpflichtet, etwaige Schäden
          an
          den gelieferten Waren unverzüglich dem anliefernden Transportunternehmen anzuzeigen.
        </li>
      </ol>
      <p class="margin-bottom"><b>VI. Beanstandungen:</b></p>
      <ol>
        <li>Der Käufer ist verpflichtet die Ware unverzüglich nach Anlieferung zu untersuchen. Weist die Lieferung
          Mängel
          auf, so müssen diese unverzüglich - spätestens innerhalb einer Woche nach Lieferung –ABC Complett schriftlich
          angezeigt werden. über den Zeitraum von 6 Monaten hinaus ist eine Haftung von ABC Complett für nicht
          erkennbare
          Mängel ausgeschlossen.
        </li>
        <li>Für Mängel, die durch Aufstellen der Einrichtungsgegenstände in feuchten Räumen entstanden sind, wird keine
          Haftung übernommen.
        </li>
        <li>Für die Montage wird vorausgesetzt, daß der Käufer einen ebenen und ausreichend tragfähigen Fußboden zur
          Verfügung stellt.
        </li>
        <li>Die zulässige Höchstbelastung bei gleichmäßig verteilter Bordbelastung der Wandregale und - Gondeln ergibt
          sich
          bei Verwendung von Borden aus Stahlblech aus den Belastungsbeispielen der jeweilig gültigen Katalog für
          Einrichtungsteile des Herstellers. Von den Beispielen abweichende Belastungen sind bei dem Hersteller oder ABC
          Complett abzuklären. Sofern vom Verkäufer von den Belastungsbeispielen abweichende Belastungen der
          Stahlblechböden
          der Wandregale und Gondeln vorgenommen werden, übernimmt ABC Complett keine Gewährleistung für auftretende
          Mängel.
        </li>
        <li>Im Falle einer änderung der von uns durchgeführten Montage durch den Kunden oder Dritter ist jede Haftung
          von
          ABC
          Complett ausgeschlossen.
        </li>
        <li>Nach Lieferung und Montage erfolgt eine Abnahme, beide Teile sind verpflichtet, daran mitzuwirken. Etwaige
          Beanstandungen sind in einem Abnahmeprotokoll aufgenommen und verborgene Mängel, die nicht binnen einer Woche
          nach
          Entdeckung schriftlich gerügt werden, können nicht mehr beanstandet werden. Gewährleistungsansprüche sind
          ferner
          ausgeschlossen, wenn an den Einrichtungsgegenständen Veränderungen vorgenommen worden sind. Geringfügige
          Abweichungen in der Ausführung, Konstruktion, Abmessungen und Farbtönen der Einrichtungsgegenständen behält
          sich
          ABC Complett vor, sie gelten nicht als Fehler. Bei begründeten Beanstandungen ist ABC Complett unter
          Ausschluss
          aller sonstigen Ansprüche, insbesondere Schadensansprüche, nach der Wahl zur Lieferung einer fehlerfreien
          Sache
          oder Nachbesserung verpflichtet. Reklamationen berechtigen nur in der Höhe zur Zurückbehaltung des
          Kaufpreises,
          die voraussichtlich zur Mängelbeseitigung durch Dritter erforderlich ist. ABC Complett kann die Nachlieferung
          oder Nachbesserung davon abhängig machen, dass der Käufer für einen zurückbehaltenen oder sonst noch offener
          Kaufpreisrest durch Bürgschaft oder Einzahlung auf ein Sperrkonto Sicherheit leistet. Falls ein Mangel die
          Funktion der Einrichtung nur unwesentlich beeinträchtigt, seine Beseitigung aber unverhältnismäßigen Aufwand
          erfordern würde, kann ABC Complett statt der Nachlieferung oder Nachbesserung eine angemessene Gutschrift
          erteilen.
        </li>
      </ol>
      <p class="margin-bottom"><b>VII. Montage:</b></p>
      <ol>
        <li>Die Kosten der Montage für die gelieferte Ladeneinrichtung oder von Teilen einer solchen sind weder in den
          kalkulierten und angebotenen noch in den endgültig berechneten Einzelpreisen enthalten. Eine andere Regelung
          muß
          ausdrücklich schriftlich vereinbart werden. Im Falle einer änderung der von uns durchgeführten Montage durch
          den
          Kunden oder Dritte, ist jegliche Haftung von ABC Complett ausgeschlossen.
        </li>
        <li>ABC Complett ist berechtigt, die Ausführung der Montage solange zu verweigern, bis die Räume in denen
          montiert
          werden soll, montagebereit sind. Auf VI 1. wird Bezug genommen. Die Räume müssen vollständig leer sein. Die
          Montage darf nicht durch andere Handwerker behindert werden, d.h. alle anderen Bauarbeiten müssen
          abgeschlossen
          sein.
        </li>
        <li>Für Wartezeiten, die ABC Complett nicht verschuldet hat, und für vom Käufer verlangte Montage an Sonn- und
          Feiertagen kann ABC Complett die anfallenden zusätzlichen Kosten berechnen.
        </li>
        <li>ABC Complett ist nicht verpflichtet, daß zur Lieferung gehörende und mitgelieferte Verpackungsmaterial
          zurückzunehmen. Die Beseitigung ist Aufgabe des Käufers.
        </li>
        <li>Im Falle der Selbstmontage sind die Hinweise der Montageanleitung für STOREbest-Einrichtungselemente sowie
          die
          Belastungsbeispiele maßgebend und vom Kunden in jedem Falle zu beachten.
        </li>
      </ol>
      <p class="margin-bottom"><b>VIII. Eigentumsvorbehalt:</b></p>
      <ol>
        <li>ABC Complett behält sich das Eigentum an den von ihr gelieferten Waren vor bis zum Ausgleich aller
          Forderungen
          aus dem jeweiligen Auftrag und bis zur vollständigen Freistellung aus allen in diesem Zusammenhang von ihr
          eingegangenen Eventualverbindlichkeiten.
        </li>
        <li>Dem Käufer ist die Verpfändung und Sicherungsübereignung der Vorbehaltsware untersagt. Der Käufer ist
          demgemäß
          verpflichtet , ABC Complett Zugriffe dritter Personen auf die unter Eigentumsvorbehalt gelieferten Waren
          unverzüglich unter übersendung eines Pfändungsprotokolls sowie einer eidesstattlichen Versicherung über die
          Indentität des gepfändeten Gegenstandes mit der gelieferten Ware anzuzeigen.
        </li>
        <li>Sofern der Käufer den Kaufpreis nicht oder nicht vollständig barzahlt, ist er verpflichtet, die Ware gegen
          Feuer-
          und Diebstahlgefahr zu versichern und der Verkäuferin auf Verlangen den Abschluß der Versicherungen
          nachzuweisen.
          Alle Ansprüche an den Versicherer aus diesem Vertrag hinsichtlich der unter Eigentumsvorbehalt gelieferten
          Ware
          werden hiermit an ABC Complett abgetreten.
        </li>
        <li>Bei Nichterfüllung der Zahlungsverpflichtungen des Käufers ist ABC Complett berechtigt, aufgrund seines
          Eigentumsvorbehaltes seine Ware jederzeit zur Sicherung seiner Forderungen zurückzunehmen. Der Käufer
          gestattet
          demgemäß dem Verkäufer und seinen Beauftragten das Betreten der Räume, in denen sich die Ware von ABC Complett
          befindet,um diese sicherzustellen.
        </li>
      </ol>
      <p class="margin-bottom"><b>IX. Zahlungsbedingungen:</b></p>
      <ol>
        <li>Rechnungen von ABC Complett sind, wie im Angebot bzw. der Auftragsbestätigung von ABC Complett schriftlich
          festgelegt, zahlbar.
        </li>
        <li>Mitarbeiter von ABC Complett sind zum Inkasso nicht berechtigt, es sei denn, daß sie eine besondere
          schriftliche
          Inkassovollmacht von ABC Complett vorweisen. Bei überschreitung des vereinbarten Zahlungstermins werden, ohne
          daß
          es einer besonderen Mahnung bedarf, für die Zeit vom Fälligkeitstage bis zum Tage des Geldeinganges
          Verzugszinsen
          in Höhe der zu dieser Zeit üblichen Zinssätze der Kreditinstitute für Kreditgewährung, mindestens aber 5 %
          über
          dem jeweiligen Diskontsatz der Deutschen Bundesbank berechnet. Diese Bestimmung findet nur im kaufmännischen
          Geschäftsverkehr Anwendung.
        </li>
        <li>Wechsel oder Schecks gelten erst mit der Einlösung als Zahlung, ABC Complett übernimmt keine Haftung für
          rechtzeitige Vorzeigung bei Kundenwechsel. Bei Annahme von Wechseln, die nur mit ausdrücklicher Genehmigung
          von
          ABC Complett möglich ist, berechnet ABC Complett die übliche Provision und Gebühren.
        </li>
        <li>Wenn seitens des Käufers die Zahlung fälliger Rechnungen nicht vereinbarungsgemäß erfolgt , werden sämtliche
          Forderungen von ABC Complett sofort fällig. Leistet der Käufer sodann auf eine von ABC Complett zu setzende
          Nachfrist von mindesten 14 Tagen keine Zahlung, so ist ABC Complett berechtigt, von den laufenden Verträgen
          zurückzutreten oder Schadensersatz gemäß IV Ziffer 3 dieser Bedingungen zu verlangen.
        </li>
      </ol>
      <p class="margin-bottom"><b>X. Behördliche Genehmigungen:</b></p>
      <ol>
        <li>Es ist Sache des Käufers auf seine Kosten alle für die Abwicklung oder Durchführung des Vertrages etwa
          notwendigen Genehmigungen, ganz gleich welcher Stelle, einzuholen. Die Firma ABC Complett ist kein
          Architekturbüro. Die erteilte Genehmigung ist ABC Complett auf Verlangen vor Lieferung nachzuweisen. Werden
          erforderliche Genehmigungen nicht erteilt oder zurückgezogen, bleiben die Ansprüche von ABC Complett aus
          diesem
          Vertrag davon unberührt.
        </li>
        <li>Der Käufer ist also dennoch verpflichtet, seinen vertraglichen Verpflichtungen nachzukommen. Die Ansprüche
          von
          ABC Complett werden auch nicht etwa gemindert, wenn die Benutzung des Kaufgegenstandes für den Käufer aus
          irgendwelchen Gründen zwecklos oder unmöglich wird, wenn ABC Complett diesen Grund nicht zu vertreten hat.
        </li>
      </ol>
      <p class="margin-bottom"><b>XI. Erfüllungsort und Gerichtsstand</b></p>
      <p class="margin-bottom justify">Erfüllungsort und Gerichtsstand ist Berlin-Schöneberg. Dies gilt auch
        hinsichtlich
        von Wechselklagen, wobei die Gerichtsvereinbarung nur zwischen Vollkaufleuten Wirksamkeit haben soll.</p>
      <p class="margin-bottom"><b>XII. Salvatorische Klausel</b></p>
      <p class="margin-bottom justify">Die Unwirksamkeit einzelner Bedingungen berührt nicht die Gültigkeit der übrigen
        Bestimmungen.</p>
      <p class="margin-bottom"><b>Download</b></p>
      <p class="justify">Unsere allgemeinen Verkaufs- und Lieferbedingungen können Sie hier als Dokument im PDF-Format
        herunterladen:
        <a href="../../../../../assets/docs/Allgemeine-Verkaufs-und-Lieferbedingungen.pdf" target="_blank">Allgemeine
          Verkaufs- und
          Lieferbedingungen (PDF)</a></p>
    </perfect-scrollbar>
  </div>
</div>
