import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-hilfe-dialog',
  templateUrl: './hilfe-dialog.component.html',
  styleUrls: ['./hilfe-dialog.component.scss']
})
export class HilfeDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<HilfeDialogComponent>,
  ) {
  }

  ngOnInit() {
  }

  closeClick(): void {
    this.dialogRef.close();
  }
}
