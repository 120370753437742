import {Component, OnInit} from '@angular/core';
import {AbcComplettApiService} from '../../services/abc-complett-api/abc-complett-api.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MenuBottomSheetComponent} from './menu-bottom-sheet/menu-bottom-sheet.component';
import {EventEmitterService} from '../../services/event-emitter/event-emitter.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  objectCount: number;
  lastModifiedDate: string;
  showHeader = true;

  constructor(
    private apiService: AbcComplettApiService,
    private bottomSheet: MatBottomSheet,
    private eventEmitter: EventEmitterService,
  ) {
    this.eventEmitter.displayHeader.subscribe(show => {
      this.showHeader = show;
    });
  }

  ngOnInit() {
    this.apiService.getStartBaseData().subscribe(data => {
      this.objectCount = data.objectCount;
      this.lastModifiedDate = data.timestamp;
    });
  }

  openBottomSheetMenu() {
    this.bottomSheet.open(MenuBottomSheetComponent);
  }
}
