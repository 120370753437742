<button class="close-button" mat-icon-button (click)="closeClick()">
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title ngClass.xs="xs">Bedienungshilfe</h1>
<div mat-dialog-content ngClass.xs="xs" ngClass.sm="sm">
  <div class="tutorial" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start center">
    <div class="gallery" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <div class="base">
        <img src="../../../../../assets/images/tutorial/gesture-scroll.png">
        <img class="scroll" src="../../../../../assets/images/tutorial/gesture-hand.png">
      </div>
      <span class="description">Scrollen auf der Seite</span>
    </div>
    <div class="gallery" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <div class="gallery-back"></div>
      <div class="base">
        <img src="../../../../../assets/images/tutorial/gesture-slide.png">
        <img class="slide" src="../../../../../assets/images/tutorial/gesture-hand.png">
      </div>
      <span class="description">Blättern in der Galerie</span>
    </div>
    <div class="gallery" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
      <div class="gallery-back"></div>
      <div class="base">
        <img class="tap" src="../../../../../assets/images/tutorial/gesture-tap.png">
        <img src="../../../../../assets/images/tutorial/gesture-hand-tap.png">
      </div>
      <span class="description">Drücken zum Bilder anschauen</span>
    </div>
  </div>
</div>
