import {Component, OnInit, Type} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {SeoService} from '../services/seo/seo.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  private handsetChange: Observable<BreakpointState>;
  private tabletChange: Observable<BreakpointState>;
  private webChange: Observable<BreakpointState>;
  private handsetChangeSubscripion: Subscription;
  private tabletChangeSubscripion: Subscription;
  private webChangeSubscripion: Subscription;

  isHandset = false;
  newsLoaded = false;
  newsComponent?: Type<any>;
  searchLoaded = false;
  searchComponent?: Type<any>;
  referenzenLoaded = false;
  referenzenComponent?: Type<any>;
  warumwirLoaded = false;
  warumwirComponent?: Type<any>;
  checklisteLoaded = false;
  checklisteComponent?: Type<any>;
  footerLoaded = false;
  footerComponent?: Type<any>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private seoService: SeoService,
  ) {
    this.handsetChange = breakpointObserver.observe([
      Breakpoints.Handset
    ]);
    this.tabletChange = breakpointObserver.observe([
      Breakpoints.Tablet
    ]);
    this.webChange = breakpointObserver.observe([
      Breakpoints.Web
    ]);

    let skipOne = false;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const url = event.url;
        if (!skipOne) {
          this.seoService.createLinkForCanonicalURL(url);
        } else {
          skipOne = false;
        }
        if (url.indexOf('/abschnitt') > -1) {
          skipOne = true;
        }
      });
  }

  ngOnInit() {
    this.handsetChangeSubscripion = this.handsetChange.subscribe(result => {
      if (result.matches) {
        this.isHandset = true;
      }
    });
    this.tabletChangeSubscripion = this.tabletChange.subscribe(result => {
      if (result.matches) {
        this.isHandset = false;
        this.initContent();
      }
    });
    this.webChangeSubscripion = this.webChange.subscribe(result => {
      if (result.matches) {
        this.isHandset = false;
        this.initContent();
      }
    });
  }

  initContent() {
    this.loadNews().then(() => {
      this.loadSearch().then(() => {
        this.loadReferenzen().then(() => {
          this.loadWarumwir().then(() => {
            this.loadCheckliste().then(() => {
              this.loadFooter();
            });
          });
        });
      });
    });
  }

  onInViewportChange(inViewport: boolean, type: string) {
    if (this.isHandset && inViewport) {
      switch (type) {
        case 'news':
          if (!this.newsLoaded) {
            this.loadNews();
          }
          break;
        case 'search':
          if (!this.searchLoaded) {
            this.loadSearch();
          }
          break;
        case 'referenzen':
          if (!this.referenzenLoaded) {
            this.loadReferenzen();
          }
          break;
        case 'warumwir':
          if (!this.warumwirLoaded) {
            this.loadWarumwir();
          }
          break;
        case 'checkliste':
          if (!this.checklisteLoaded) {
            this.loadCheckliste();
          }
          break;
        case 'footer':
          if (!this.footerLoaded) {
            this.loadFooter();
          }
          break;
      }
    }
  }

  loadNews() {
    return import('../news.module')
      .then(mod => mod.NewsModule)
      .then(lazyModule => {
        this.newsComponent = lazyModule.components.news;
        this.newsLoaded = true;
      });
  }

  loadSearch() {
    return import('../search.module')
      .then(mod => mod.SearchModule)
      .then(lazyModule => {
        this.searchComponent = lazyModule.components.search;
        this.searchLoaded = true;
      });
  }

  loadReferenzen() {
    return import('../referenzen.module')
      .then(mod => mod.ReferenzenModule)
      .then(lazyModule => {
        this.referenzenComponent = lazyModule.components.referenzen;
        this.referenzenLoaded = true;
      });
  }

  loadWarumwir() {
    return import('../warumwir.module')
      .then(mod => mod.WarumwirModule)
      .then(lazyModule => {
        this.warumwirComponent = lazyModule.components.warumwir;
        this.warumwirLoaded = true;
      });
  }

  loadCheckliste() {
    return import('../checkliste.module')
      .then(mod => mod.ChecklisteModule)
      .then(lazyModule => {
        this.checklisteComponent = lazyModule.components.checkliste;
        this.checklisteLoaded = true;
      });
  }

  loadFooter() {
    return import('../footer.module')
      .then(mod => mod.FooterModule)
      .then(lazyModule => {
        this.footerComponent = lazyModule.components.footer;
        this.footerLoaded = true;
      });
  }

}
