import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Laden, LadenGallery, ReferenzGallery} from '../../models/Laden';
import {Branche} from '../../models/Branche';
import {Kontakt} from '../../models/Kontakt';
import {Checkliste} from '../../models/Checkliste';
import {SuchTreffer} from '../../models/Suche';
import {SearchKeywords} from '../../models/SearchKeywords';
import {ImageDataType} from '../../enums/ImageDataType';

@Injectable({
  providedIn: 'root'
})
export class AbcComplettApiService {

  private readonly apiBaseUrl = 'https://api.abc-complett.de/';

  newsObjectRoyalDonuts: Laden = {
    id: -1,
    name: 'Royal Donuts',
    text1: 'Spandauer Arcaden',
    text2: 'Berlin',
    branche: 'Gastronomie',
    branchenId: 6,
    images: [
      '01_royal-donuts-spandauer-arcaden-berlin-ladenbau-abc-complett.jpg?v1',
      '02_royal-donuts-spandauer-arcaden-berlin-ladenbau-abc-complett.jpg?v1',
      '03_royal-donuts-spandauer-arcaden-berlin-ladenbau-abc-complett.jpg?v1',
      '04_royal-donuts-spandauer-arcaden-berlin-ladenbau-abc-complett.jpg?v1',
      '05_royal-donuts-spandauer-arcaden-berlin-ladenbau-abc-complett.jpg?v1',
      '06_royal-donuts-spandauer-arcaden-berlin-ladenbau-abc-complett.jpg?v1',
      '07_royal-donuts-spandauer-arcaden-berlin-ladenbau-abc-complett.jpg?v1',
      '08_royal-donuts-spandauer-arcaden-berlin-ladenbau-abc-complett.jpg?v1',
    ],
    isLocalObject: true,
  };

  newsObjectGewerbe: Laden = {
    id: -2,
    name: 'Vermarktungshilfen für Gewerbeimmobilien',
    text1: undefined,
    text2: undefined,
    branche: 'Vermarktungshilfe',
    branchenId: undefined,
    images: [
      '01_Bestandsfoto.jpg?v1',
      '02_Aufmaß.jpg?v1',
      '03_Grundriss.jpg?v1',
      '04_Einrichtungsplan.jpg?v1',
      '05_3DAnimation-Visualisierung-Homestaging-Shopstaging-Restaurantstaging.jpg?v1',
    ],
    imageTexts: [
      'Bestandsfoto',
      'Aufmaß',
      'Grundriss',
      'Einrichtungsplan',
      '3D-Animation / Visualisierung / Shopstaging / Restaurantstaging',
    ],
    isLocalObject: true,
  };

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getNewsObjectRoyalDonuts(): Observable<Laden> {
    return of(this.newsObjectRoyalDonuts);
  }

  getNewsObjectGewerbe(): Observable<Laden> {
    return of(this.newsObjectGewerbe);
  }

  getKompetenzObjects(): Observable<Laden[]> {
    return this.httpClient.get<Laden[]>(this.apiBaseUrl + 'getKompetenzObjects.php', {responseType: 'json'});
  }

  getStartObjects(): Observable<{ kompetenzen: LadenGallery[] }> {
    return this.httpClient.get<{ kompetenzen: LadenGallery[] }>(this.apiBaseUrl + 'getStartObjects.php', {responseType: 'json'});
  }

  getStartBaseData(): Observable<{ timestamp: string, objectCount: number }> {
    return this.httpClient.get<{ timestamp: string, objectCount: number }>(this.apiBaseUrl + 'getStartBaseData.php',
      {responseType: 'json'});
  }

  getReferenzObjects(): Observable<{ kompetenzen: ReferenzGallery[] }> {
    return this.httpClient.get<{ kompetenzen: ReferenzGallery[] }>(this.apiBaseUrl + 'getReferenzObjects.php', {responseType: 'json'});
  }

  getBranchen(): Observable<Branche[]> {
    return this.httpClient.get<Branche[]>(this.apiBaseUrl + 'getBranchen.php', {responseType: 'json'});
  }

  sendMailKontakt(contact: Kontakt): Observable<any> {
    return this.httpClient.post<any>(this.apiBaseUrl + 'sendMailKontakt.php', contact, {responseType: 'json'});
  }

  sendMailCheckliste(checkliste: Checkliste): Observable<any> {
    return this.httpClient.post<any>(this.apiBaseUrl + 'sendMailCheckliste.php', checkliste, {responseType: 'json'});
  }

  performSearch(query: string): Observable<SuchTreffer> {
    return this.httpClient.post<SuchTreffer>(this.apiBaseUrl + 'suche.php', {query}, {responseType: 'json'});
  }

  persistSearchLog(query: string, count: number): Observable<any> {
    return this.httpClient.post<any>(this.apiBaseUrl + 'sucheProtokoll.php', {query, count}, {responseType: 'json'});
  }

  getObjectPicturesByObjectId(ladenId: number): Observable<Laden> {
    if (ladenId > 0) {
      return this.httpClient.post<Laden>(this.apiBaseUrl + 'getObject.php', {
        id: ladenId.toString()
      }, {responseType: 'json'});
    } else {
      if (ladenId === -1) {
        return this.getNewsObjectRoyalDonuts();
      } else if (ladenId === -2) {
        return this.getNewsObjectGewerbe();
      }
    }
  }

  getObjectPicturesByBranchenId(branchenId: number): Observable<Laden> {
    return this.httpClient.post<Laden>(this.apiBaseUrl + 'getObject.php', {
      branchenId: branchenId.toString()
    }, {responseType: 'json'});
  }

  getPreviousObject(laden: Laden, type: ImageDataType): Observable<Laden> {
    return this.httpClient.post<Laden>(this.apiBaseUrl + 'getPreviousObject.php', {
      id: laden.id.toString(),
      type: type.toString()
    }, {responseType: 'json'});
  }

  getNextObject(laden: Laden, type: ImageDataType): Observable<Laden> {
    return this.httpClient.post<Laden>(this.apiBaseUrl + 'getNextObject.php', {
      id: laden.id.toString(),
      type: type.toString()
    }, {responseType: 'json'});
  }

  getSearchKeywords(): Observable<SearchKeywords> {
    return this.httpClient.get<SearchKeywords>(this.apiBaseUrl + 'getSearchKeywords.php', {responseType: 'json'});
  }

  getSearchKeywordsByBranchenId(branchenId: number): Observable<[{ begriff: string }]> {
    const params = new HttpParams();
    params.append('brId', branchenId.toString());
    return this.httpClient.get<[{ begriff: string }]>(this.apiBaseUrl + 'getSearchKeywordsByBranchenId.php', {
      responseType: 'json',
      params
    });
  }
}
