import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {HeaderComponent} from './components/header/header.component';
import {BranchenComponent} from './components/branchen/branchen.component';
import {MenuBottomSheetComponent} from './components/header/menu-bottom-sheet/menu-bottom-sheet.component';
import {MainComponent} from './components/main.component';
import {PageSectionComponent} from './components/page-hooks/page-section.component';
import {PageDialogComponent} from './components/page-hooks/page-dialog.component';
import {PageObjectComponent} from './components/page-hooks/page-object.component';
import {PageBrancheComponent} from './components/page-hooks/page-branche.component';
import {CookieComponent} from './components/cookie/cookie.component';
import {SharedModule} from './shared.module';
import {CommonModule} from '@angular/common';
import {InViewportModule} from '@elvirus/angular-inviewport';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {DeactivateGuard} from './guard/deactivate-guard';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        BranchenComponent,
        MenuBottomSheetComponent,
        MainComponent,
        PageSectionComponent,
        PageDialogComponent,
        PageObjectComponent,
        PageBrancheComponent,
        CookieComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        InViewportModule,
        LazyLoadImageModule,
    ],
    providers: [DeactivateGuard],
    bootstrap: [AppComponent]
})
export class AppModule {
}
