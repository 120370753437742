import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  checklistenBrancheSelectionSet: EventEmitter<number>;
  cookiePopup: EventEmitter<void>;
  displayHeader: EventEmitter<boolean>;
  disableScroll: EventEmitter<boolean>;
  activateGoogleAnalytics: EventEmitter<void>;

  constructor() {
    this.checklistenBrancheSelectionSet = new EventEmitter<number>();
    this.cookiePopup = new EventEmitter<void>();
    this.displayHeader = new EventEmitter<boolean>();
    this.disableScroll = new EventEmitter<boolean>();
    this.activateGoogleAnalytics = new EventEmitter<void>();
  }

  emitChecklistenBranchenSelectionSet(branchenId: number) {
    this.checklistenBrancheSelectionSet.emit(branchenId);
  }

  emitCookiePopup() {
    this.cookiePopup.emit();
  }

  emitDisplayHeader(show: boolean) {
    this.displayHeader.emit(show);
  }

  emitDisableScroll(disable: boolean) {
    this.disableScroll.emit(disable);
  }

  emitActivateGoogleAnalytics() {
    this.activateGoogleAnalytics.emit();
  }
}
