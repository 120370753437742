import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {metaData} from '../../data/meta-data';

@Component({
  selector: 'app-page-section',
  template: '',
})
export class PageSectionComponent implements OnInit {

  private lastSectionPosition = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const sectionId = params.abschnittId;
      if (sectionId) {
        this.adjustScrollPosition(sectionId);

        switch (sectionId) {
          case 'kompetenzen':
            this.title.setTitle(metaData.kompetenzen.title);
            this.meta.updateTag({name: 'description', content: metaData.kompetenzen.description});
            this.meta.updateTag({name: 'keywords', content: metaData.kompetenzen.keywords});
            break;
          case 'news':
            this.title.setTitle(metaData.news.title);
            this.meta.updateTag({name: 'description', content: metaData.news.description});
            this.meta.updateTag({name: 'keywords', content: metaData.news.keywords});
            break;
          case 'fertig':
            this.title.setTitle(metaData.fertig.title);
            this.meta.updateTag({name: 'description', content: metaData.fertig.description});
            this.meta.updateTag({name: 'keywords', content: metaData.fertig.keywords});
            break;
          case 'gemeinsam':
            this.title.setTitle(metaData.gemeinsam.title);
            this.meta.updateTag({name: 'description', content: metaData.gemeinsam.description});
            this.meta.updateTag({name: 'keywords', content: metaData.gemeinsam.keywords});
            break;
          case 'denkdran':
            this.title.setTitle(metaData.denkdran.title);
            this.meta.updateTag({name: 'description', content: metaData.denkdran.description});
            this.meta.updateTag({name: 'keywords', content: metaData.denkdran.keywords});
            break;
          case 'top':
          default:
            this.title.setTitle(metaData.default.title);
            this.meta.updateTag({name: 'description', content: metaData.default.description});
            this.meta.updateTag({name: 'keywords', content: metaData.default.keywords});
            break;
        }

        this.router.navigate(['/'], {skipLocationChange: true});
      }
    });
  }

  private adjustScrollPosition(sectionId: string) {
    const scroller = document.getElementById('app-root');
    const element = document.getElementById(sectionId);
    const fixHeader = document.getElementById('fix-header');
    const secondHeader = document.getElementById('second-header');

    if (scroller && element) {
      const headerHeight = (fixHeader ? fixHeader.clientHeight : 0) + (secondHeader ? secondHeader.clientHeight : 0);
      const elementRect = element.offsetTop;
      const elementPosition = elementRect - headerHeight;

      scroller.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });

      if (this.lastSectionPosition !== elementPosition) {
        this.lastSectionPosition = elementPosition;
        setTimeout(() => {
          this.adjustScrollPosition(sectionId);
        }, 500);
      }
    } else {
      setTimeout(() => {
        this.adjustScrollPosition(sectionId);
      }, 100);
    }
  }

}
