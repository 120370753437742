<mat-toolbar *ngIf="showHeader" id="fix-header" color="primary" class="fix-header-toolbar" fxLayout="row"
             fxLayoutGap="8px">
  <button (click)="openBottomSheetMenu()" color="accent" mat-icon-button fxHide.gt-xs>
    <mat-icon>menu</mat-icon>
  </button>
  <a [routerLink]="['/abschnitt/top']">
    <button mat-flat-button color="primary" [disableRipple]="true" class="title" fxHide.lt-sm fxFlex="0 0 auto">ABC
      Complett
    </button>
  </a>
  <div class="menu" fxLayoutGap="8px" fxHide.lt-md>
    <a [routerLink]="['/abschnitt/kompetenzen']">
      <button mat-flat-button color="primary">Kompetenzen!</button>
    </a>
    <a [routerLink]="['/abschnitt/news']">
      <button mat-flat-button color="primary">News!</button>
    </a>
    <a [routerLink]="['/abschnitt/fertig']">
      <button mat-flat-button color="primary">Fertig!</button>
    </a>
    <a [routerLink]="['/abschnitt/gemeinsam']">
      <button mat-flat-button color="primary">Gemeinsam!</button>
    </a>
    <a [routerLink]="['/abschnitt/denkdran']">
      <button mat-flat-button color="primary">Denk Dran!</button>
    </a>
  </div>
  <span fxFlex="1 1 auto"></span>
  <a [routerLink]="['/abschnitt/fertig']">
    <button mat-icon-button color="accent">
      <mat-icon>search</mat-icon>
    </button>
  </a>
  <a [routerLink]="['/dialog/route']">
    <button mat-flat-button color="primary" fxHide.lt-lg>Route</button>
    <button mat-icon-button color="accent" fxHide fxShow.md fxShow.sm fxShow.xs>
      <mat-icon>place</mat-icon>
    </button>
  </a>
  <a [routerLink]="['/dialog/telefon']">
    <button mat-flat-button color="primary" fxHide.lt-lg>Tel</button>
    <button mat-icon-button color="accent" fxHide fxShow.md fxShow.sm fxShow.xs>
      <mat-icon>phone</mat-icon>
    </button>
  </a>
  <a [routerLink]="['/dialog/kontakt']">
    <button mat-flat-button color="primary" fxHide.lt-lg>Kontakt</button>
    <button mat-icon-button color="accent" fxHide fxShow.md fxShow.sm fxShow.xs>
      <mat-icon>mail</mat-icon>
    </button>
  </a>
</mat-toolbar>
<mat-toolbar *ngIf="showHeader" id="second-header" class="second-header" color="primary" fxHide fxShow.sm>
  <div fxLayoutGap="8px">
    <a [routerLink]="['/abschnitt/kompetenzen']">
      <button mat-flat-button color="primary">Kompetenzen!</button>
    </a>
    <a [routerLink]="['/abschnitt/news']">
      <button mat-flat-button color="primary">News!</button>
    </a>
    <a [routerLink]="['/abschnitt/fertig']">
      <button mat-flat-button color="primary">Fertig!</button>
    </a>
    <a [routerLink]="['/abschnitt/gemeinsam']">
      <button mat-flat-button color="primary">Gemeinsam!</button>
    </a>
    <a [routerLink]="['/abschnitt/denkdran']">
      <button mat-flat-button color="primary">Denk Dran!</button>
    </a>
  </div>
</mat-toolbar>
<mat-toolbar id="top" color="primary" class="header-graphic" [ngClass.sm]="{'sm-size': true}">
  <span *ngIf="objectCount" fxHide.lt-sm class="object-count">{{objectCount}} Shops mit Fotos</span>
  <span *ngIf="lastModifiedDate" fxHide.lt-sm class="lastmodified">zuletzt aktualisiert am {{lastModifiedDate}}</span>
</mat-toolbar>
<div class="abc-logo" [ngClass.sm]="{'sm-size': true}">
  <div fxLayout="column" fxLayoutAlign="end center" fxFlex="1 1 auto" fxLayoutGap="2px">
    <strong class="title">ABC Complett</strong>
    <div class="subtitle">Ladeneinrichtungs GmbH</div>
    <div><a href="tel:+49308599710">030 85 99 71 - 0</a></div>
  </div>
</div>
