import {Component, OnInit} from '@angular/core';
import {CookieStorageService} from '../../services/cookie-storage/cookie-storage.service';
import {EventEmitterService} from '../../services/event-emitter/event-emitter.service';
import {metaData} from '../../data/meta-data';
import {Meta, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {

  displayPopup = true;

  get cookieDenied(): boolean {
    return this.cookieStorage.hasDenied;
  }

  get isGoogleBot(): boolean {
    return window.navigator.userAgent.indexOf('Googlebot') > -1;
  }

  constructor(
    private cookieStorage: CookieStorageService,
    private eventemitter: EventEmitterService,
    private title: Title,
    private meta: Meta,
    private router: Router,
  ) {
    this.eventemitter.cookiePopup.subscribe(() => {
      this.displayPopup = true;
    });
    this.displayPopup = !this.cookieStorage.hasAgreed;
    if (this.isGoogleBot) {
      this.cookieStorage.hasAgreed = true;
      this.displayPopup = false;
    }
  }

  ngOnInit() {
  }

  cancelClicked() {
    this.displayPopup = false;
    this.cookieStorage.hasAgreed = false;
    this.title.setTitle(metaData.default.title);
    this.meta.updateTag({name: 'description', content: metaData.default.description});
    this.meta.updateTag({name: 'keywords', content: metaData.default.keywords});
    this.router.navigate(['/'], {});
  }

  confirmClicked() {
    this.displayPopup = false;
    this.cookieStorage.hasAgreed = true;
    this.router.navigate(['/'], {});
    this.eventemitter.emitActivateGoogleAnalytics();
  }
}
