import {NgModule} from '@angular/core';
import {LadenDialogComponent} from './components/ui/dialog/laden-dialog/laden-dialog.component';
import {LadenDetailDialogComponent} from './components/ui/dialog/laden-detail-dialog/laden-detail-dialog.component';
import {MapDialogComponent} from './components/ui/dialog/map-dialog/map-dialog.component';
import {PhoneDialogComponent} from './components/ui/dialog/phone-dialog/phone-dialog.component';
import {ContactDialogComponent} from './components/ui/dialog/contact-dialog/contact-dialog.component';
import {ImpressumDialogComponent} from './components/ui/dialog/impressum-dialog/impressum-dialog.component';
import {DatenschutzDialogComponent} from './components/ui/dialog/datenschutz-dialog/datenschutz-dialog.component';
import {BedingungenDialogComponent} from './components/ui/dialog/bedingungen-dialog/bedingungen-dialog.component';
import {HilfeDialogComponent} from './components/ui/dialog/hilfe-dialog/hilfe-dialog.component';
import {GalleryScrollerComponent} from './components/ui/gallery-scroller/gallery-scroller.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from './material.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {HammerModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LazyLoadImageModule} from 'ng-lazyload-image';

@NgModule({
    declarations: [
        GalleryScrollerComponent,
        MapDialogComponent,
        PhoneDialogComponent,
        ContactDialogComponent,
        LadenDialogComponent,
        ImpressumDialogComponent,
        DatenschutzDialogComponent,
        BedingungenDialogComponent,
        LadenDetailDialogComponent,
        HilfeDialogComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        HammerModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        LazyLoadImageModule,
    ],
    exports: [
        FlexLayoutModule,
        MaterialModule,
        PerfectScrollbarModule,
        HammerModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        GalleryScrollerComponent,
        MapDialogComponent,
        PhoneDialogComponent,
        ContactDialogComponent,
        LadenDialogComponent,
        ImpressumDialogComponent,
        DatenschutzDialogComponent,
        BedingungenDialogComponent,
        LadenDetailDialogComponent,
        HilfeDialogComponent,
    ]
})
export class SharedModule {
}
