import {Component, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {Meta, Title} from '@angular/platform-browser';
import {metaData} from '../../../../data/meta-data';

@Component({
  selector: 'app-datenschutz-dialog',
  templateUrl: './datenschutz-dialog.component.html',
  styleUrls: ['./datenschutz-dialog.component.scss']
})
export class DatenschutzDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DatenschutzDialogComponent>,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle(metaData.dialog.datenschutz.title);
    this.meta.updateTag({name: 'description', content: metaData.dialog.datenschutz.description});
    this.meta.updateTag({name: 'keywords', content: metaData.dialog.datenschutz.keywords});
  }

  ngOnInit() {
  }

  closeClick(): void {
    this.dialogRef.close();
  }

}
