<button class="close-button" mat-icon-button (click)="closeClick()">
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>Weitere Shops aus {{branche}}</h1>
<div mat-dialog-content ngClass.xs="xs">
  <div class="content">
    <div class="text" fxLayout="column" fxLayoutAlign="start center">
      <perfect-scrollbar>
        <div *ngFor="let laden of ladenObjects" class="laden-card" [ngClass]="{'inline': laden.name == ''}">
          <h3 *ngIf="laden.name != ''">{{laden.name}}</h3>
          <p [innerHTML]="laden.text1"></p>
        </div>
      </perfect-scrollbar>
    </div>
    <button mat-flat-button color="accent" class="checkliste-button" (click)="gotoCheckliste()">
      Denk Dran! Deine Checkliste!
    </button>
  </div>
</div>
