import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Kontakt} from '../../../../models/Kontakt';
import {NgForm} from '@angular/forms';
import {AbcComplettApiService} from '../../../../services/abc-complett-api/abc-complett-api.service';
import {Meta, Title} from '@angular/platform-browser';
import {metaData} from '../../../../data/meta-data';
import {Observable, Subscription} from 'rxjs';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';

export interface ContactDialogData {
  title: string;
}

@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss']
})
export class ContactDialogComponent implements OnInit, OnDestroy {

  @ViewChild('contactForm', { read: NgForm }) contactForm;

  private handsetChange: Observable<BreakpointState>;
  private tabletChange: Observable<BreakpointState>;
  private webChange: Observable<BreakpointState>;
  private landscapeChange: Observable<BreakpointState>;
  private portraitChange: Observable<BreakpointState>;
  private handsetChangeSubscripion: Subscription;
  private tabletChangeSubscripion: Subscription;
  private webChangeSubscripion: Subscription;
  private landscapeChangeSubscripion: Subscription;
  private portraitChangeSubscripion: Subscription;
  private isLandscape = false;
  private isHandset = false;

  contact: Kontakt;
  succeded = false;
  error: string;
  landscapeHandset = false;

  constructor(
    private apiService: AbcComplettApiService,
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContactDialogData,
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.contact = {
      kontaktPerMail: true,
      kontaktPerTelefon: true
    };
    this.title.setTitle(metaData.dialog.contact.title);
    this.meta.updateTag({name: 'description', content: metaData.dialog.contact.description});
    this.meta.updateTag({name: 'keywords', content: metaData.dialog.contact.keywords});
    this.handsetChange = breakpointObserver.observe([
      Breakpoints.Handset
    ]);
    this.tabletChange = breakpointObserver.observe([
      Breakpoints.Tablet
    ]);
    this.webChange = breakpointObserver.observe([
      Breakpoints.Web
    ]);
    this.landscapeChange = breakpointObserver.observe([
      Breakpoints.HandsetLandscape
    ]);
    this.portraitChange = breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]);
  }

  ngOnInit() {
    this.portraitChangeSubscripion = this.portraitChange.subscribe(result => {
      if (result.matches) {
        this.isLandscape = false;
      }
    });
    this.landscapeChangeSubscripion = this.landscapeChange.subscribe(result => {
      if (result.matches) {
        this.isLandscape = true;
        if (this.isHandset) {
          this.landscapeHandset = true;
        }
      }
    });
    this.handsetChangeSubscripion = this.handsetChange.subscribe(result => {
      if (result.matches) {
        this.isHandset = true;
        if (this.isLandscape) {
          this.landscapeHandset = true;
        }
      }
    });
    this.tabletChangeSubscripion = this.tabletChange.subscribe(result => {
      if (result.matches) {
        this.isHandset = false;
      }
    });
    this.webChangeSubscripion = this.webChange.subscribe(result => {
      if (result.matches) {
        this.isHandset = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.portraitChangeSubscripion.unsubscribe();
    this.landscapeChangeSubscripion.unsubscribe();
    this.handsetChangeSubscripion.unsubscribe();
    this.tabletChangeSubscripion.unsubscribe();
    this.webChangeSubscripion.unsubscribe();
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  sendForm() {
    if (this.contactForm && this.contactForm.valid) {
      this.apiService.sendMailKontakt(this.contact).subscribe(result => {
        if (result.status === 'success') {
          this.succeded = true;
          this.error = undefined;
        } else {
          this.succeded = false;
          this.error = result.message;
        }
      });
    }
  }

  inputFocus(event: any) {
    if (this.isHandset && this.isLandscape) {
      event.target.parentElement.scrollIntoView({behavior: 'smooth'});
    }
  }
}
