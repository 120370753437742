export const metaData = {
  default: {
    title: 'ABC Complett Ladeneinrichtungs GmbH | Ladenbau Berlin | ABC Complett',
    description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Hier findest Du Kompetenz im Ladenbau in und um Berlin. Fotos aus über 200 realisierten Shops laden zum Stöbern ein - Denk dran! Deine Checkliste zur Ladenplanung steht für Dich bereit!',
    keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Checkliste, Friedenau, Umbau, Ausbau, Planung, Erfahrung, Festpreis, Innenarchitektur, Innenausbau, Beleuchtung, Gesundheit, Gastronomie, Nonfood, Lebensmittel, Optiker, Apotheker, Apotheke, Apotheken, Naturkost, Naturkostmärkte, Bio, Reformhaus, Reformhäuser, Coffeeshop, Coffee Shop, Coffeeshops, Coffee Shops, Gastronomie, Imbiss, Imbiß, Feinkost, Textilien, DOB, HOB, Geschenkartikel, Porzellan, Uhren, Schmuck, Fanshop, Fanshops, Merchandising, Parfümerie, Parfümerien, Drogerie, Drogerien, Bio, Bäcker, Biobäcker, Backshop, Backshops, Confisserie, Obst, Gemüse, Obst und Gemüse, Teeladen, Teeläden, Buchladen, Buchläden, Möbelhaus, Audio & Video; Video, Audio Video, Shopfitter, Shopfitting'
  },
  kompetenzen: {
    title: 'Kompetenzen! Mehr als Branchen | Ladenbau Berlin',
    description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Hier findest Du Fotos aus unseren Kompetenzen Gesundheit, Gastronomie, Non Food und Lebensmittel - eine Kompetenz, mehr als Branchen',
    keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Checkliste, Friedenau, Umbau, Ausbau, Planung, Erfahrung, Festpreis, Innenarchitektur, Innenausbau, Beleuchtung, Gesundheit, Gastronomie, Nonfood, Lebensmittel, Optiker, Apotheker, Apotheke, Apotheken, Naturkost, Naturkostmärkte, Bio, Reformhaus, Reformhäuser, Coffeeshop, Coffee Shop, Coffeeshops, Coffee Shops, Gastronomie, Imbiss, Imbiß, Feinkost, Textilien, DOB, HOB, Geschenkartikel, Porzellan, Uhren, Schmuck, Fanshop, Fanshops, Merchandising, Parfümerie, Parfümerien, Drogerie, Drogerien, Bio, Bäcker, Biobäcker, Backshop, Backshops, Confisserie, Obst, Gemüse, Obst und Gemüse, Teeladen, Teeläden, Buchladen, Buchläden, Möbelhaus, Audio & Video; Video, Audio Video, Shopfitter, Shopfitting'
  },
  news: {
    title: 'News! Aktuelle Projekte | Ladenbau Berlin',
    description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Hier findest Du Bilder und Beschreibungen aktueller Projekte.',
    keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Checkliste, Friedenau, Umbau'
  },
  fertig: {
    title: 'Fertig! Unsere Referenzliste | Ladenbau Berlin',
    description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Hier findest Du Fotos aus unseren Kompetenzen Gesundheit, Gastronomie, Non Food und Lebensmittel - eine Kompetenz , mehr als Branchen. Starte Deine Suche jetzt!',
    keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Checkliste, Friedenau, Umbau, Ausbau, Planung, Erfahrung, Festpreis, Innenarchitektur, Innenausbau, Beleuchtung, Gesundheit, Gastronomie, Nonfood, Lebensmittel, Optiker, Apotheker, Apotheke, Apotheken, Naturkost, Naturkostmärkte, Bio, Reformhaus, Reformhäuser, Coffeeshop, Coffee Shop, Coffeeshops, Coffee Shops, Gastronomie, Imbiss, Imbiß, Feinkost, Textilien, DOB, HOB, Geschenkartikel, Porzellan, Uhren, Schmuck, Fanshop, Fanshops, Merchandising, Parfümerie, Parfümerien, Drogerie, Drogerien, Bio, Bäcker, Biobäcker, Backshop, Backshops, Confisserie, Obst, Gemüse, Obst und Gemüse, Teeladen, Teeläden, Buchladen, Buchläden, Möbelhaus, Audio & Video; Video, Audio Video, Shopfitter, Shopfitting'
  },
  gemeinsam: {
    title: 'Gemeinsam! Von der Idee zum Shop | Ladenbau Berlin',
    description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Auf Basis Deiner Ideen planen wir gemeinsam Deinen unverwechselbaren Shop! Nutze unsere Erfahrung über die Shoperöffnung hinaus.',
    keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Checkliste, Friedenau, Umbau, Ausbau, Planung, Erfahrung, Festpreis, Innenarchitektur, Innenausbau, Beleuchtung, Gesundheit, Gastronomie, Nonfood, Lebensmittel, Optiker, Apotheker, Apotheke, Apotheken, Naturkost, Naturkostmärkte, Bio, Reformhaus, Reformhäuser, Coffeeshop, Coffee Shop, Coffeeshops, Coffee Shops, Gastronomie, Imbiss, Imbiß, Feinkost, Textilien, DOB, HOB, Geschenkartikel, Porzellan, Uhren, Schmuck, Fanshop, Fanshops, Merchandising, Parfümerie, Parfümerien, Drogerie, Drogerien, Bio, Bäcker, Biobäcker, Backshop, Backshops, Confisserie, Obst, Gemüse, Obst und Gemüse, Teeladen, Teeläden, Buchladen, Buchläden, Möbelhaus, Audio & Video; Video, Audio Video, Shopfitter, Shopfitting'
  },
  denkdran: {
    title: 'Denk Dran! Checklisten - Für Deine Planung | Ladenbau Berlin',
    description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Deine Checklisten - unsere Planungshilfen für Dein Projekt!',
    keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Checkliste, Friedenau, Umbau, Ausbau, Planung, Erfahrung, Festpreis, Innenarchitektur, Innenausbau, Beleuchtung, Gesundheit, Gastronomie, Nonfood, Lebensmittel, Optiker, Apotheker, Apotheke, Apotheken, Naturkost, Naturkostmärkte, Bio, Reformhaus, Reformhäuser, Coffeeshop, Coffee Shop, Coffeeshops, Coffee Shops, Gastronomie, Imbiss, Imbiß, Feinkost, Textilien, DOB, HOB, Geschenkartikel, Porzellan, Uhren, Schmuck, Fanshop, Fanshops, Merchandising, Parfümerie, Parfümerien, Drogerie, Drogerien, Bio, Bäcker, Biobäcker, Backshop, Backshops, Confisserie, Obst, Gemüse, Obst und Gemüse, Teeladen, Teeläden, Buchladen, Buchläden, Möbelhaus, Audio & Video; Video, Audio Video, Shopfitter, Shopfitting'
  },
  dialog: {
    bedingungen: {
      title: 'Unsere Geschäftsbedingungen | Ladenbau Berlin',
      description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. AGBs',
      keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Geschäftsbedingungen, AGBs'
    },
    contact: {
      title: 'Nimm Kontakt mit uns auf. ABC Complett Ladeneinrichtungs Gmbh | Ladenbau Berlin',
      description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Kontaktangaben',
      keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Kontaktangaben'
    },
    datenschutz: {
      title: 'Datenschutz | Ladenbau Berlin',
      description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Datenschutz.',
      keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Datenschutz, DSGVO, Cookies'
    },
    impressum: {
      title: 'Impressum und Rechtliches | Ladenbau Berlin',
      description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Impressum',
      keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Impressum'
    },
    map: {
      title: 'Karte / Route | Ladenbau Berlin',
      description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. So erreichst Du uns.',
      keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Karte / Route'
    },
    phone: {
      title: 'Telefonkontakt | Ladenbau Berlin',
      description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. Wir freuen uns auf Deinen Anruf!',
      keywords: 'Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Telefonkontakt'
    },
    laden: {
      title: '{0} aus {0} | Ladenbau Berlin',
      titleShort: '{0} | Ladenbau Berlin',
      description: 'ABC Complett Ladenbau Berlin Ladeneinrichtung Berlin. {0} - starte jetzt Dein eigenes Projekt!',
      keywords: '{0}, Ladenbau Berlin, Berlin Ladenbau, Ladeneinrichtung Berlin, Berlin Ladeneinrichtung, Ladenbau, Berlin, ABC Complett Ladeneinrichtungs GmbH, Checkliste, Friedenau, Umbau, Ausbau, Planung, Erfahrung, Festpreis, Innenarchitektur'
    }
  }
};
