<button class="close-button" mat-icon-button (click)="closeClick()">
  <mat-icon>close</mat-icon>
</button>
<h1 mat-dialog-title>Telefon</h1>
<div mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="center center">
    <p>Du kannst uns gern anrufen:</p>
    <a href="tel:+49308599710">030 85 99 71 - 0</a>
  </div>
</div>
