import { CanDeactivate } from '@angular/router';
import {LadenDialogComponent} from '../components/ui/dialog/laden-dialog/laden-dialog.component';
import {PageObjectComponent} from '../components/page-hooks/page-object.component';

export class DeactivateGuard implements CanDeactivate<PageObjectComponent> {

  canDeactivate(component: PageObjectComponent) {
      return component.canDeactivate();
  }
}
