import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Meta, Title} from '@angular/platform-browser';
import {metaData} from '../../../../data/meta-data';

export interface MapDialogData {
  title: string;
}

@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MapDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MapDialogData,
    private title: Title,
    private meta: Meta,
  ) {
    this.title.setTitle(metaData.dialog.map.title);
    this.meta.updateTag({name: 'description', content: metaData.dialog.map.description});
    this.meta.updateTag({name: 'keywords', content: metaData.dialog.map.keywords});
  }

  ngOnInit() {
  }

  closeClick(): void {
    this.dialogRef.close();
  }

}
