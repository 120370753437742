<div fxLayout="column" fxLayoutGap="4px">
  <a [routerLink]="['/abschnitt/kompetenzen']">
    <button mat-button color="accent" (click)="itemClicked()">Kompetenzen!</button>
  </a>
  <a [routerLink]="['/abschnitt/news']">
    <button mat-button color="accent" (click)="itemClicked()">News!</button>
  </a>
  <a [routerLink]="['/abschnitt/fertig']">
    <button mat-button color="accent" (click)="itemClicked()">Fertig!</button>
  </a>
  <a [routerLink]="['/abschnitt/gemeinsam']">
    <button mat-button color="accent" (click)="itemClicked()">Gemeinsam!</button>
  </a>
  <a [routerLink]="['/abschnitt/denkdran']">
    <button mat-button color="accent" (click)="itemClicked()">Denk Dran!</button>
  </a>
</div>
