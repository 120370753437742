import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Laden} from '../../../models/Laden';
import {MatDialog} from '@angular/material/dialog';
import {LadenDialogComponent} from '../dialog/laden-dialog/laden-dialog.component';
import {ImageDataType} from '../../../enums/ImageDataType';
import {LadenDetailDialogComponent} from '../dialog/laden-detail-dialog/laden-detail-dialog.component';
import {CookieStorageService} from '../../../services/cookie-storage/cookie-storage.service';
import {EventEmitterService} from '../../../services/event-emitter/event-emitter.service';

@Component({
  selector: 'app-gallery-scroller',
  templateUrl: './gallery-scroller.component.html',
  styleUrls: ['./gallery-scroller.component.scss']
})
export class GalleryScrollerComponent implements OnInit {

  @ViewChild('scroller', {static: true})
  scroller: ElementRef;

  @Input()
  ladenObjects: Laden[];

  @Input()
  noPicObjects: Laden[];

  @Input()
  imageDataType: ImageDataType = ImageDataType.DEFAULT_BRANCHE;

  @Input()
  showIndividualLabels = false;

  @Input()
  individualLabelKey = 'kompetenz';

  @Input()
  showLabelsOnlyOnFirstItem = false;

  @Input()
  newsObject = false;

  @Input()
  newsObjectId = 0;

  @Input()
  newsObjectName = '';

  @Input()
  newsObjectBranche = '';

  @Input()
  mediumImages = false;

  @Input()
  smallImages = false;

  @Input()
  doubleCards = false;

  @Input()
  numberOfDoubleCards = 2;

  @Input()
  linear = false;

  @Input()
  blueArrows = false;

  @Input()
  autoScrolling = false;

  private autoScrollTime = 3000;

  currentIndex = 0;
  buttonsEnabled = true;
  startGradient = false;
  endGradient = true;
  scrollLeft: number;
  scrollWidth: number;
  offsetWidth: number;

  get newsObjId(): number {
    return this.newsObjectId;
  }

  get newsObjName(): string {
    return this.newsObjectName;
  }

  get imageUrlPrefix(): string {
    if (this.newsObject) {
      return '/assets/images/news/' + this.newsObjectName + '/';
    } else if (this.smallImages) {
      return 'https://images.abc-complett.de/thumbs_120/';
    }
    return 'https://images.abc-complett.de/thumbs/';
  }

  get canScrollFurther(): boolean {
    const nativeElement = this.scroller.nativeElement;
    const scrollLeft = Math.floor(nativeElement.scrollLeft);
    const scrollWidth = Math.floor(nativeElement.scrollWidth);
    const offsetWidth = Math.floor(nativeElement.offsetWidth);
    return scrollLeft !== (scrollWidth - offsetWidth);
  }

  private get cookiesAgreed(): boolean {
    return this.cookieStorage.hasAgreed;
  }

  constructor(
    public dialog: MatDialog,
    private cookieStorage: CookieStorageService,
    private eventEmitter: EventEmitterService,
  ) {
  }

  ngOnInit() {
    if (this.autoScrolling) {
      setTimeout(() => {
        this.scrollTimer();
      }, this.autoScrollTime);
    }
  }

  getImageApiUrl(item: string, imageElement: HTMLImageElement): string {
    return 'https://api.abc-complett.de/getImage.php?image='
      + item
      + '&width='
      + imageElement.offsetWidth
      + '&height='
      + imageElement.offsetHeight;
  }

  scrollTimer() {
    if (this.autoScrolling) {
      if (this.canScrollFurther) {
        if ((!this.newsObject && this.currentIndex < this.ladenObjects.length - 1)
          || (this.newsObject && this.currentIndex < this.ladenObjects[0].images.length - 1)) {
          this.currentIndex++;
        }
        this.scrollToNextElement();
      } else {
        this.currentIndex = 0;
        this.scroller.nativeElement.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
      }
      setTimeout(() => {
        this.scrollTimer();
      }, this.autoScrollTime);
    }
  }

  forwardButtonClicked() {
    if (this.cookiesAgreed) {
      this.startButtonTimer();
      if ((!this.newsObject && this.currentIndex < this.ladenObjects.length - 1)
        || (this.newsObject && this.currentIndex < this.ladenObjects[0].images.length - 1)) {
        this.currentIndex++;
      }
      this.scrollToNextElement();
    } else {
      this.eventEmitter.emitCookiePopup();
    }
  }

  backButtonClicked() {
    if (this.cookiesAgreed) {
      this.startButtonTimer();
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
      this.scrollToPreviousElement();
    } else {
      this.eventEmitter.emitCookiePopup();
    }
  }

  scrollPageIfNeeded(laden: Laden) {
    const scroller = document.getElementById('app-root');
    if (scroller.scrollTop <= 180) {
      const sectionId = 'kompetenzen';
      const element = document.getElementById(sectionId);
      const fixHeader = document.getElementById('fix-header');
      const secondHeader = document.getElementById('second-header');
      const headerHeight = (fixHeader ? fixHeader.clientHeight : 0) + (secondHeader ? secondHeader.clientHeight : 0);
      const elementRect = element.offsetTop;
      const elementPosition = elementRect - headerHeight;

      scroller.scrollTo({
        top: elementPosition
      });
    }
  }

  openSingleLadenDialog(index: number) {
    this.dialog.open(LadenDialogComponent, {
      data: {
        laden: this.ladenObjects[0],
        localUrls: true,
        localUrlsName: this.newsObjectName,
        selectedLocalIndex: index
      },
      autoFocus: false
    });
  }

  openNoPicDialog() {
    this.dialog.open(LadenDetailDialogComponent, {
      data: {
        branchenId: this.ladenObjects[0].branchenId,
        branche: this.ladenObjects[0].branche,
        ladenObjects: this.noPicObjects
      },
      autoFocus: false
    });
  }

  private startButtonTimer() {
    this.buttonsEnabled = false;
    setTimeout(() => {
      this.buttonsEnabled = true;
    }, 500);
  }

  private scrollToPreviousElement() {
    const nativeElement = this.scroller.nativeElement;
    if (nativeElement.scrollWidth > nativeElement.clientWidth && this.currentIndex >= 0) {
      this.scrollToCurrentElement();
    }
  }

  private scrollToNextElement() {
    const nativeElement = this.scroller.nativeElement;
    if (nativeElement.scrollWidth > nativeElement.clientWidth && this.canScrollFurther) {
      this.scrollToCurrentElement();
    }
  }

  private scrollToCurrentElement() {
    const elements = this.scroller.nativeElement.querySelectorAll('.content img');
    let width = 0;
    for (let i = 0; i < this.currentIndex; i++) {
      const element = elements[i];
      width += element.clientWidth + 10;
    }
    this.scroller.nativeElement.scrollTo({
      left: width,
      behavior: 'smooth'
    });
  }

  replaceLadenName(name: string): string {
    return name.replace(new RegExp(' ', 'g'), '-');
  }
}
