import {Component, OnInit} from '@angular/core';
import {MatBottomSheetRef} from '@angular/material/bottom-sheet';
import smoothscroll from 'smoothscroll-polyfill';

@Component({
  selector: 'app-menu-bottom-sheet',
  templateUrl: './menu-bottom-sheet.component.html',
  styleUrls: ['./menu-bottom-sheet.component.scss']
})
export class MenuBottomSheetComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<MenuBottomSheetComponent>,
  ) {
  }

  ngOnInit() {
    smoothscroll.polyfill();
  }

  itemClicked(): void {
    this.bottomSheetRef.dismiss();
  }
}
