import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {EventEmitterService} from '../../../../services/event-emitter/event-emitter.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Laden} from '../../../../models/Laden';
import {Meta, Title} from '@angular/platform-browser';
import {metaData} from '../../../../data/meta-data';

export interface LadenDetailDialogData {
  branchenId: number;
  branche: string;
  ladenObjects: Laden[];
}

@Component({
  selector: 'app-laden-detail-dialog',
  templateUrl: './laden-detail-dialog.component.html',
  styleUrls: ['./laden-detail-dialog.component.scss']
})
export class LadenDetailDialogComponent implements OnInit, OnDestroy {

  branche: string;
  ladenObjects: Laden[];

  constructor(
    private eventEmitterService: EventEmitterService,
    public dialogRef: MatDialogRef<LadenDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LadenDetailDialogData,
    private title: Title,
    private meta: Meta,
  ) {
  }

  ngOnInit() {
    this.branche = this.data.branche;
    this.ladenObjects = this.prepareLadenObjects();
    this.title.setTitle('Weitere Shops aus ' + this.branche + ' | Ladenbau Berlin');
    this.meta.updateTag({name: 'description', content: 'Weitere Shops aus ' + this.branche + ' | Ladenbau Berlin'});
  }

  ngOnDestroy() {
    this.title.setTitle(metaData.default.title);
    this.meta.updateTag({name: 'description', content: metaData.default.description});
  }

  private prepareLadenObjects(): Laden[] {
    const ladenObjects: Laden[] = [];
    let lastMasterLaden: Laden;
    for (const laden of this.data.ladenObjects) {
      if (lastMasterLaden && lastMasterLaden.name === laden.name) {
        lastMasterLaden.text1 += '<br/>' + this.joinLadenTextStrings(laden);
      } else {
        if (lastMasterLaden) {
          ladenObjects.push(lastMasterLaden);
        }
        lastMasterLaden = laden;
        lastMasterLaden.text1 = this.joinLadenTextStrings(lastMasterLaden);
      }
    }
    if (lastMasterLaden) {
      ladenObjects.push(lastMasterLaden);
    }
    return ladenObjects;
  }

  private joinLadenTextStrings(laden: Laden) {
    let result = '';
    result += laden.text1;
    if (result.length > 0 && laden.text2 && laden.text2.length > 0) {
      result += ' | ';
    }
    result += laden.text2;
    return result;
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  gotoCheckliste() {
    this.dialogRef.close();
    const sectionId = 'denkdran';
    const scroller = document.getElementById('app-root');
    const element = document.getElementById(sectionId);
    const fixHeader = document.getElementById('fix-header');
    const secondHeader = document.getElementById('second-header');
    const headerHeight = (fixHeader ? fixHeader.clientHeight : 0) + (secondHeader ? secondHeader.clientHeight : 0);
    const elementRect = element.offsetTop;
    const elementPosition = elementRect - headerHeight;

    setTimeout(() => {
      scroller.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
    }, 200);
    if (this.data.branchenId) {
      this.eventEmitterService.emitChecklistenBranchenSelectionSet(this.data.branchenId);
    } else {
      this.eventEmitterService.emitChecklistenBranchenSelectionSet(undefined);
    }
  }
}
