import {Component, OnInit} from '@angular/core';
import {MapDialogComponent} from '../ui/dialog/map-dialog/map-dialog.component';
import {PhoneDialogComponent} from '../ui/dialog/phone-dialog/phone-dialog.component';
import {ContactDialogComponent} from '../ui/dialog/contact-dialog/contact-dialog.component';
import {ImpressumDialogComponent} from '../ui/dialog/impressum-dialog/impressum-dialog.component';
import {DatenschutzDialogComponent} from '../ui/dialog/datenschutz-dialog/datenschutz-dialog.component';
import {BedingungenDialogComponent} from '../ui/dialog/bedingungen-dialog/bedingungen-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieStorageService} from '../../services/cookie-storage/cookie-storage.service';
import {EventEmitterService} from '../../services/event-emitter/event-emitter.service';
import {metaData} from '../../data/meta-data';
import {Meta, Title} from '@angular/platform-browser';

@Component({
  selector: 'app-page-dialog',
  template: '',
})
export class PageDialogComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private cookieStorage: CookieStorageService,
    private eventemitter: EventEmitterService,
    private title: Title,
    private meta: Meta,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const dialogId = params.dialogId;
      if (this.cookieStorage.hasAgreed || dialogId === 'datenschutz') {
        if (dialogId) {
          let dialogRef;
          switch (dialogId) {
            case 'route':
              dialogRef = this.dialog.open(MapDialogComponent, {
                autoFocus: false
              });
              break;
            case 'telefon':
              dialogRef = this.dialog.open(PhoneDialogComponent, {
                autoFocus: false
              });
              break;
            case 'kontakt':
              dialogRef = this.dialog.open(ContactDialogComponent, {
                autoFocus: false
              });
              break;
            case 'impressum':
              dialogRef = this.dialog.open(ImpressumDialogComponent, {
                autoFocus: false
              });
              break;
            case 'datenschutz':
              dialogRef = this.dialog.open(DatenschutzDialogComponent, {
                autoFocus: false
              });
              break;
            case 'bedingungen':
              dialogRef = this.dialog.open(BedingungenDialogComponent, {
                autoFocus: false
              });
              break;
          }
          if (dialogRef) {
            dialogRef.afterClosed().subscribe(result => {
              this.title.setTitle(metaData.default.title);
              this.meta.updateTag({name: 'description', content: metaData.default.description});
              this.meta.updateTag({name: 'keywords', content: metaData.default.keywords});
              this.router.navigate(['/'], {});
            });
          }
        }
      } else {
        this.eventemitter.emitCookiePopup();
      }
    });
  }

}
