import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './components/main.component';
import {PageSectionComponent} from './components/page-hooks/page-section.component';
import {PageDialogComponent} from './components/page-hooks/page-dialog.component';
import {PageObjectComponent} from './components/page-hooks/page-object.component';
import {PageBrancheComponent} from './components/page-hooks/page-branche.component';
import {DeactivateGuard} from './guard/deactivate-guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {path: 'abschnitt/:abschnittId', component: PageSectionComponent},
      {path: 'branche/:branchenId/:name', component: PageBrancheComponent},
      {path: 'shop/:ladenId/:typ/:name/:branche', component: PageObjectComponent, canDeactivate: [DeactivateGuard]},
      {path: 'shop/:ladenId/:typ/:name/:branche/:bildId', component: PageObjectComponent, canDeactivate: [DeactivateGuard]},
      {path: 'dialog/:dialogId', component: PageDialogComponent},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
}
