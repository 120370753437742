<div class="container" fxLayout="row"
     [ngClass]="{'no-gradient-start': !startGradient, 'no-gradient-end': !endGradient}">
  <button [ngClass]="{'hide': currentIndex == 0,'blue': blueArrows}" class="back-button" mat-button fxFlex="40px"
          (click)="backButtonClicked()" [disabled]="!buttonsEnabled" fxHide.lt-md>
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <div #scroller class="scroller" fxLayout="row" fxLayoutGap="10px" fxFlex="1 1 auto">
    <ng-container *ngIf="!newsObject">
      <a #content [id]="'image' + i" *ngFor="let laden of ladenObjects; let i = index" class="content"
         [ngClass]="{'small': smallImages, 'medium': mediumImages}"
         [routerLink]="['/shop/' + laden.id + '/' + imageDataType + '/' + replaceLadenName(laden.name) + '/' + laden.branche + '/1']"
         (click)="scrollPageIfNeeded(laden);content.blur()" fxLayout="row">
        <h3
          *ngIf="showIndividualLabels && (!showLabelsOnlyOnFirstItem || (currentIndex == i && i == 0) || currentIndex == i)"
          [ngClass]="{'double-card': doubleCards && ((numberOfDoubleCards > 1 && i < numberOfDoubleCards) || (currentIndex == i))}">{{laden[individualLabelKey]}}</h3>
        <img #image1 data-src="{{imageUrlPrefix + laden.defaultItem}}"
             defaultImage="../../../assets/images/ui/image-placeholder.png"
             lazyLoad="{{getImageApiUrl(laden.defaultItem, image1)}}" alt="Geschäftsansicht Ladenbau Berlin"
             [ngClass]="{'double-card': doubleCards && ((numberOfDoubleCards > 1 && i < numberOfDoubleCards) || (currentIndex == i))}">
        <div class="double-card" [ngClass]="{
             'header-margin': showIndividualLabels && (!showLabelsOnlyOnFirstItem || i == 0),
             'hide': !doubleCards || ((numberOfDoubleCards > 1 && i < numberOfDoubleCards) || (currentIndex != i))
             }" [ngClass.lt-md]="{'hide': !doubleCards}">
          <p class="name">{{laden.name}}</p>
          <p class="text1">{{laden.text1}}</p>
          <p class="text2">{{laden.text2}}</p>
        </div>
      </a>
      <div #noPicContent *ngIf="noPicObjects && noPicObjects.length > 0" class="content nopic" fxLayout="column"
           (click)="openNoPicDialog();noPicContent.blur()" [ngClass]="{'small': smallImages, 'medium': mediumImages}">
        <perfect-scrollbar>
          <h4>{{noPicObjects[0].branche}}:</h4>
          <ng-container *ngFor="let laden of noPicObjects">
            <p class="np-name">{{laden.name}}</p>
            <p>{{laden.text2}}</p>
          </ng-container>
        </perfect-scrollbar>
      </div>
    </ng-container>
    <ng-container *ngIf="newsObject">
      <a #singleObjectContent [id]="'image' + i" *ngFor="let image of ladenObjects[0].images; let i = index;"
         class="content" (click)="singleObjectContent.blur()"
         [ngClass]="{'small': smallImages, 'medium': mediumImages}"
         [routerLink]="['/shop/' + newsObjectId + '/' + i + '/' + newsObjName + '/' + newsObjectBranche + '/1']"
         fxLayout="row">
        <h3 *ngIf="showIndividualLabels && (!showLabelsOnlyOnFirstItem || i == 0)">
          {{ladenObjects[0][individualLabelKey]}}
        </h3>
        <img defaultImage="../../../assets/images/ui/image-placeholder.png"
             lazyLoad="{{imageUrlPrefix + 'thumbs/' + image}}" alt="Geschäftsansicht Ladenbau Berlin">
      </a>
    </ng-container>
  </div>
  <button
    [ngClass]="{
    'hide': linear ? (newsObject ? currentIndex == ladenObjects[0].images?.length - 1 : currentIndex == ladenObjects?.length - 1) : !canScrollFurther,
    'blue': blueArrows
    }"
    class="forward-button" mat-button fxFlex="40px" (click)="forwardButtonClicked()"
    [disabled]="!buttonsEnabled" fxHide.lt-md>
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
</div>
